import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography
} from '@mui/material';
import {
  ConnectSocketAdditionalParams,
  DeckType
} from '../../../../types/types';
import { useAuth } from '../../../../contexts/AuthContext';

type PlayButtonScreenProps = {
  connectSocket: (additionalParams: ConnectSocketAdditionalParams) => void;
  userDecks: DeckType[];
  selectedDeckName: string;
  setSelectedDeckName: (value: string) => void;
};

export const PlayButtonScreen = ({
  connectSocket,
  userDecks,
  selectedDeckName,
  setSelectedDeckName
}: PlayButtonScreenProps) => {
  const { accessToken } = useAuth();
  const [lobbyUsers, setLobbyUsers] = useState<number | 'error' | null>(null);
  const [usersInGame, setUsersInGame] = useState<number | 'error' | null>(null);

  useEffect(() => {
    fetchLobbyUsers();
    fetchUsersInGame();
  }, []);

  const fetchLobbyUsers = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_TCG_URL}/globalLobby`
      );
      const data = await response.json();
      console.log('fetchLobbyUsers data', data);
      setLobbyUsers(data.globalLobbyCount);
    } catch (error) {
      setLobbyUsers('error');
    }
  };

  const fetchUsersInGame = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_TCG_URL}/gameRooms`
      );
      const data = await response.json();
      setUsersInGame(data.totalUsersPlaying);
    } catch (error) {
      setUsersInGame('error');
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: `calc(100vh - 4rem)`,
        width: '100%'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          mt: '-6rem',
          justifyContent: 'space-between'
        }}
      >
        {lobbyUsers !== null && lobbyUsers !== 'error' && (
          <Box
            sx={{
              width: '125px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid white',
              borderRadius: '4px',
              p: '1rem',
              mr: '2rem'
            }}
          >
            <Typography variant="h4" fontWeight="bold">
              {lobbyUsers}
            </Typography>
            <Typography variant="h6" fontWeight="bold">
              Waiting
            </Typography>
          </Box>
        )}
        {usersInGame !== null && usersInGame !== 'error' && (
          <Box
            sx={{
              width: '125px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid white',
              borderRadius: '4px',
              p: '1rem'
            }}
          >
            <Typography variant="h4" fontWeight="bold">
              {usersInGame}
            </Typography>
            <Typography variant="h6" fontWeight="bold">
              Playing
            </Typography>
          </Box>
        )}
      </Box>
      <Box sx={{ pt: '4rem' }}>
        {userDecks.length > 0 ? (
          <FormControl sx={{ width: '250px' }}>
            <InputLabel id="deck-select-label">Select Deck</InputLabel>
            <Select
              labelId="deck-select-label"
              id="deck-select"
              label="Select Deck"
              onChange={(e) => {
                setSelectedDeckName(e.target.value);
              }}
              value={selectedDeckName}
            >
              {userDecks.map((deck, index) => {
                return (
                  <MenuItem key={index} value={deck.deckName}>
                    {deck.deckName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        ) : (
          <Button>Please create a deck</Button>
        )}
      </Box>
      <Box sx={{ pt: '2rem' }}>
        <Typography
          variant="h4"
          fontWeight="bold"
          textAlign="center"
          sx={{ mb: '1rem' }}
        >
          VS
        </Typography>
        <Button
          size="large"
          variant="contained"
          onClick={() =>
            connectSocket({
              botGame: false,
              deckName: selectedDeckName,
              token: accessToken ?? ''
            })
          }
          sx={{ mr: '2rem', width: '100px' }}
          disabled={userDecks.length <= 0}
        >
          Users
        </Button>
        <Button
          size="large"
          variant="contained"
          onClick={() =>
            connectSocket({
              botGame: true,
              deckName: selectedDeckName,
              token: accessToken ?? ''
            })
          }
          sx={{ width: '100px' }}
          disabled={userDecks.length <= 0}
        >
          Bot
        </Button>
      </Box>
    </Box>
  );
};
