import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import { LoginForm, TwoFactorAuthValidationForm } from './components';
import './Login.css'
import { JoinNow } from '../../components/WhitePaper'

export const Login = () => {
  const { isLoggedIn, redirectUrl } = useAuth();

  const navigate = useNavigate();
  const [rememberUser, setRememberUser] = useState<boolean>(false);

  const [preAccessToken, setPreAccessToken] = useState<any>();

  useEffect(() => {
    if (isLoggedIn) {
      navigate(redirectUrl ?? '/member-portal');
    }
  }, [isLoggedIn, navigate, redirectUrl]);

  return (
    <Grid container component="main" sx={{ height: '100vh' }} className='login'>
        <JoinNow />
      {preAccessToken ?
        (
          <TwoFactorAuthValidationForm
            rememberUser={rememberUser}
            preAccessToken={preAccessToken}
          />
        ) :
        (
          <LoginForm
            setRememberUser={setRememberUser}
            setPreAccessToken={setPreAccessToken}
          />
        )}
    </Grid>
  );
};
