import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

export const ProtectedRoute = () => {
  const { isLoggedIn, saveRedirectUrl } = useAuth();

  if (!isLoggedIn) {
    saveRedirectUrl();
    return <Navigate to="/login" />;
  }

  return <Outlet />;
};
