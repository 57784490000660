import React, {
  createContext,
  ReactNode,
  useEffect,
  useState,
  useCallback,
  useContext
} from 'react';
import { io, Socket } from 'socket.io-client';
import { ConnectSocketAdditionalParams } from '../types/types';

interface SocketContextType {
  socket: Socket | null;
  connectSocket: (additionalParams: ConnectSocketAdditionalParams) => void;
  disconnectSocket: () => void;
  connectionError: string | null;
}

export const SocketContext = createContext<SocketContextType | undefined>(
  undefined
);

export const SocketProvider = ({
  children,
  username
}: {
  children: ReactNode;
  username: string;
}) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [connectionError, setConnectionError] = useState<string | null>(null);

  const connectSocket = useCallback(
    (additionalParams: ConnectSocketAdditionalParams) => {
      if (!socket) {
        const ioSocket = io(`${process.env.REACT_APP_TCG_URL}`, {
          query: { username, ...additionalParams }
        });

        ioSocket.on('connect_error', (error) => {
          setConnectionError(
            'Connection Error: Unable to connect to the server. Please try again later.'
          );
          console.error('Connection Error:', error);
        });

        ioSocket.on('connect_failed', () => {
          setConnectionError(
            'Connection Failed: Unable to connect to the server. Please try again later.'
          );
          console.error('Connection Failed');
        });

        ioSocket.on('connect', () => {
          setConnectionError(null); // Clear any previous error when connected
        });
        setSocket(ioSocket);
      }
    },
    [socket, username]
  );

  const disconnectSocket = useCallback(() => {
    if (socket) {
      socket.disconnect();
      setSocket(null);
    }
  }, [socket]);

  useEffect(() => {
    // Cleanup socket on component unmount
    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [socket]);

  return (
    <SocketContext.Provider
      value={{ socket, connectSocket, disconnectSocket, connectionError }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => {
  const context = useContext(SocketContext);
  if (!context) {
    throw new Error('SocketContext must be used within a SocketProvider');
  }
  return context;
};
