import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Link,
  Radio,
  RadioGroup
} from '@mui/material';
import { useUser } from '../../../../contexts/UserContext';
import { useNetwork } from '../../../../utils/network';
import { useShowSnackbar } from '../../../../utils/snackbar';

type GiveawayEligibleModalProps = {
  isOpen: boolean;
  handleClose: () => void;
};

export const GiveawayEligibleModal = ({
  isOpen,
  handleClose
}: GiveawayEligibleModalProps) => {
  const { user, setUser } = useUser();
  const { post } = useNetwork();
  const { showSnackbar } = useShowSnackbar();

  const [readDocs, setReadDocs] = useState<boolean>(false);
  const [isGiveawayEligible, setIsGiveawayEligible] = useState<boolean | null>(
    null
  );

  const giveawayEligibilityUpdatedSubmit = async () => {
    try {
      const result = await post(
        '/user/updateUserGiveawayEligibility',
        { giveawayEligibilityStatus: isGiveawayEligible },
        true
      );
      setUser({
        ...user,
        isGiveawayEligible: isGiveawayEligible
      });
      handleClose();
      showSnackbar(result.message, 'success');
    } catch (error: any) {
      showSnackbar(error.response.data.message, 'error');
    }
  };

  return (
    <Dialog
      open={isOpen}
      // onClose={handleClose}
      fullWidth
      maxWidth="sm"
      scroll="paper" // Enable scrolling for content
      disableEscapeKeyDown={true}
    >
      <DialogTitle textAlign="center" sx={{ fontWeight: 'bold' }}>
        T&Cs
      </DialogTitle>

      <DialogContent dividers>
        <Box sx={{ px: 2 }}>
          <Typography
            variant="h5"
            fontWeight="bold"
            textAlign="center"
            sx={{ pb: '2rem' }}
          >
            Please Read the T&Cs
          </Typography>
          <Typography sx={{ pb: '1rem' }}>
            T&Cs are found here:{' '}
            <Link
              href="https://incrypt-public-assets.s3.ap-southeast-2.amazonaws.com/terms_and_conditions.docx"
              rel="noopener noreferrer"
              variant="body2"
              target="_blank"
              style={{ fontSize: '16px' }}
            >
              INCRYPT Terms & Conditions
            </Link>
          </Typography>

          <FormControlLabel
            control={
              <Checkbox
                onChange={() => {
                  setReadDocs(!readDocs);
                }}
              />
            }
            label={
              <Typography>
                I have read and agree to the{' '}
                <Link
                  href="https://incrypt-public-assets.s3.ap-southeast-2.amazonaws.com/terms_and_conditions.docx"
                  rel="noopener noreferrer"
                  variant="body2"
                  target="_blank"
                  style={{ fontSize: '16px' }}
                >
                  terms and conditions
                </Link>
              </Typography>
            }
            labelPlacement="end"
            sx={{ my: '1rem' }}
          />

          <FormControl>
            <FormLabel id="giveaway-Eligibility">
              You have read the T&Cs and are eligible for giveaways
            </FormLabel>
            <RadioGroup
              onChange={(e) => setIsGiveawayEligible(e.target.value === 'yes')}
            >
              <FormControlLabel
                value="yes"
                control={<Radio required={true} />}
                label="Yes I am eligible for giveaways"
              />
              <FormControlLabel
                value="no"
                control={<Radio required={true} />}
                label="No I am not eligible for giveaways"
              />
            </RadioGroup>
          </FormControl>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          onClick={giveawayEligibilityUpdatedSubmit}
          disabled={readDocs === false || isGiveawayEligible === null}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
