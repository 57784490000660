import React from 'react';
import { Box, Typography, Avatar, Stack, Button } from '@mui/material';
import backgroundImage from '../../assets/Images/white-paper-background.webp'
import eyeImage from '../../assets/Images/eye.png'
import { useNavigate } from 'react-router-dom';

const WhitePaper = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        height: '100dvh', 
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        p: 4,
      }}
    >
      {/* Top Section */}
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          height: '100px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {/* Centered 'Incrypt' */}
        <Typography
          variant="h1"
          sx={{
            fontFamily: 'Paladins, Arial, sans-serif',
            textAlign: 'center',
            fontSize: {
              xs: '3rem',
              md: '4rem', 
              lg: '5rem', 
              xl: '6rem', 
            },
            lineHeight: {
              xs: 1.2, 
              sm: 1.3,
              md: 1.4,
              lg: 1.5,
            },
            margin: {
              xs: '8px 0', 
              md: '16px 0',
            },
          }}
        >
          Incrypt
        </Typography>

        {/* Row for 'October' and '2024' on small screens */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            gap: '8px', 
            marginTop: { xs: '8px', md: 0 }, 
            position: { md: 'absolute' }, 
            left: { md: '16px' }, 
            top: { md: '8px' },
            right: { md: '16px' }, 
          }}
        >
          <Typography
            variant="h4"
            sx={{
              position: { xs: 'static', md: 'absolute' }, 
              left: { md: '16px' }, 
            }}
          >
            October
          </Typography>

          <Typography
            variant="h4"
            sx={{
              position: { xs: 'static', md: 'absolute' }, 
              right: { md: '16px' }, 
            }}
          >
            2024
          </Typography>
        </Box>
      </Box>


      {/* Middle Section */}
      <Stack
        spacing={2}
        sx={{ alignItems: 'center', textAlign: 'center' }}
      >
        <Avatar src={eyeImage} alt="Middle Image" sx={{
          width: {
            xs: 100,  
            sm: 150,  
            md: 200,  
            lg: 250,  
            xl: 300,  
          },
          height: {
            xs: 100,  
            sm: 150,
            md: 200,
            lg: 250,
            xl: 300,
          },
          margin: 'auto', 
        }} />
        <Typography variant="h4">Please login or signup to view our white paper</Typography>
      </Stack>

      {/* Bottom Section */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          variant="outlined"
          sx={{
            fontSize: { xs: '1rem', md: '1.5rem' }, 
            borderRadius: '50px', 
            padding: { xs: '12px 24px', md: '16px 32px' }, 
            textTransform: 'none', 
            marginTop: { xs: '24px', md: '32px' }, 
          }}
          onClick={() => navigate('/login')}
        >
          Login/Register
        </Button>
      </Box>
    </Box>
  );
};

export default WhitePaper;
