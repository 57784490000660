import React, { useState, ReactNode, MouseEvent, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  Box,
  Typography,
  Divider,
  ListItemIcon,
  Tooltip,
  Avatar,
  Menu,
  MenuItem
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SIDEBAR_DATA } from '../../../constants/data';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import logo from '../../../assets/Images/logo-menu.png';
import { useUser } from '../../../contexts/UserContext';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useWallet } from '@solana/wallet-adapter-react';
import { useNetwork } from '../../../utils/network';
import { useShowSnackbar } from '../../../utils/snackbar';
const drawerWidth = 240;
const collapsedWidth = 60;

interface MenuBarProps {
  children: ReactNode;
}

const MenuBar: React.FC<MenuBarProps> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMobile = useMediaQuery('(max-width: 600px)');
  const { logout } = useAuth();
  const { user, setUser } = useUser();
  const [imageError, setImageError] = useState(false);
  const { publicKey, connected } = useWallet();
  const { put } = useNetwork();
  const { showSnackbar } = useShowSnackbar();
  useEffect(() => {
    const saveWalletAddress = async () => {
      if (connected && publicKey) {
        try {
          const result = await put(
            '/user/wallet-address',
            { walletAddress: publicKey.toString() },
            true
          );

          if (result.updated) {
            showSnackbar('Wallet address saved successfully.', 'success');
            setUser({ ...user, walletAddress: publicKey.toString() });
          }
        } catch (error: any) {
          showSnackbar(
            error.response?.data?.message || 'Failed to save wallet address',
            'error'
          );
        }
      }
    };

    saveWalletAddress();
  }, [connected, publicKey]);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleNavigation = (path: string) => {
    navigate(path);
    if (isMobile) {
      setMobileOpen(false);
    }
  };

  const isSelected = (path: string) => {
    const fullPath = `/member-portal${path ? `/${path}` : ''}`;
    return location.pathname === fullPath;
  };

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleMenuClose();
    logout();
  };

  const drawer = (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Toolbar />
      <Divider />
      <List>
        {SIDEBAR_DATA.map(
          (
            { name, icon: Icon, path, disabled, optionalDisabledText },
            index
          ) => (
            <Tooltip
              key={index}
              title={
                disabled
                  ? optionalDisabledText
                    ? optionalDisabledText
                    : 'Coming soon'
                  : sidebarOpen
                    ? ''
                    : name
              }
              placement="right"
            >
              <div>
                <ListItemButton
                  sx={{ minHeight: 64 }}
                  onClick={() => handleNavigation(path)}
                  selected={isSelected(path)}
                  disabled={disabled}
                >
                  <ListItemIcon>
                    <Icon />
                  </ListItemIcon>
                  {(isMobile || sidebarOpen) && (
                    <ListItemText
                      primary={name}
                      sx={{ whiteSpace: 'nowrap' }}
                    />
                  )}
                </ListItemButton>
              </div>
            </Tooltip>
          )
        )}
      </List>
      {!isMobile && (
        <Box
          onClick={handleSidebarToggle}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%', // Make the button take the full width
            mt: 'auto', // Push to the bottom
            cursor: 'pointer', // Change the cursor to a pointer
            p: 1, // Padding for hover effect
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.08)' // Add hover effect
            }
          }}
        >
          {sidebarOpen ? (
            <>
              <Typography variant="body2" sx={{ ml: 1 }}>
                Collapse
              </Typography>
              <ChevronLeftIcon />
            </>
          ) : (
            <ChevronRightIcon />
          )}
        </Box>
      )}
    </Box>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          {/* Menu drawer toggle button */}
          {isMobile ? (
            <IconButton
              color="inherit"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          ) : null}
          <Box
            component="img"
            src={logo}
            alt="Logo"
            sx={{
              width: '56px',
              height: '56px',
              marginTop: '8px',
              marginBottom: '8px'
            }}
          />
          <Typography
            variant="h6"
            noWrap
            component="div"
            //sx={{ marginRight: '25px' }}
          >
            {!isMobile && 'INC Balance'} {user.tokenAmount}
          </Typography>
          <WalletMultiButton />
          <IconButton color="inherit" onClick={handleMenuOpen}>
            {imageError ? (
              <Avatar>
                <AccountCircleIcon />
              </Avatar>
            ) : (
              <Avatar
                alt={user.username}
                src={`${process.env.REACT_APP_PROFILE_PICTURE_URL}/${user.uuid}.jpg?refresh=${user.refreshImage}`}
                onError={() => setImageError(true)}
                sx={{
                  width: '3rem',
                  height: '3rem',
                  border: '2px solid black',
                  boxShadow: '0 0 0 1px grey'
                }}
              >
                {user.username.charAt(0).toUpperCase()}
              </Avatar>
            )}
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
          >
            <MenuItem
              onClick={() => {
                handleMenuClose();
                handleNavigation('settings');
              }}
            >
              Settings
            </MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        open={isMobile ? mobileOpen : sidebarOpen}
        onClose={handleDrawerToggle}
        sx={{
          width: sidebarOpen || isMobile ? drawerWidth : collapsedWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: sidebarOpen || isMobile ? drawerWidth : collapsedWidth,
            overflowX: 'hidden',
            transition: 'width 0.3s',
            boxSizing: 'border-box',
            paddingTop: '8px'
          }
        }}
      >
        {drawer}
      </Drawer>
      <Box
        sx={{
          flexGrow: 1,
          transition: 'width 0.3s'
        }}
      >
        <Toolbar /> {/* Provides space for AppBar */}
        {children}
      </Box>
    </Box>
  );
};

export default MenuBar;
