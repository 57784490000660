import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { ReferredUsersType } from '../../../../types/types';
import { StyledDataGrid } from '../../../../constants/styling';
import { useNetwork } from '../../../../utils/network';
type SettingsUserReferralsProps = {
  isSmallSize?: boolean;
};

export const SettingsUserReferrals = ({
  isSmallSize
}: SettingsUserReferralsProps) => {
  const { get } = useNetwork();
  const [listOfUsersReferred, setListOfUsersReferred] = useState<ReferredUsersType[]>([]);
  const [amountEarned, setAmountEarned] = useState(0)

  useEffect(() => {
    const fetchReferredUsers = async () => {
      try {
        const data = await get('/user/referrals', true);
        setListOfUsersReferred(data.referrals);
        setAmountEarned(data.earnt)
      } catch (error) {
        console.error('Failed to fetch referred users:', error);
      }
    };

    fetchReferredUsers();
  }, []);

  const referredUsersTableColumns = [
    { headerName: 'Username', field: 'username', flex: 1 },
    { headerName: 'Date Joined', field: 'joinDate', flex: 1 }
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column'}}>
      <Box sx={{ pb: '1.5rem' }}>
        <Typography variant="h6" textAlign="center" fontWeight="medium">
          Your referral earnings increase as you refer more users. Each milestone reached boosts your earnings multiplier:
        </Typography>
        <Typography variant="body2" textAlign="center" sx={{ mt: 1 }}>
          <strong>Milestones:</strong>
          <br />10+ referrals = 2x, 20+ referrals = 3x, 30+ referrals = 4x, 40+ referrals = 5x, 50+ referrals = 6x
        </Typography>
        <Typography variant="body1" textAlign="center" sx={{ mt: 2 }}>
          <strong>Current Multiplier:</strong> {listOfUsersReferred.length >= 50 ? 6
            : listOfUsersReferred.length >= 40 ? 5
              : listOfUsersReferred.length >= 30 ? 4
                : listOfUsersReferred.length >= 20 ? 3
                  : listOfUsersReferred.length >= 10 ? 2
                    : 1}
        </Typography>
      </Box>
      <Box
        sx={{
          pb: '2rem',
          display: 'flex',
          flexDirection: isSmallSize ? 'column' : 'row',
          gap: '2rem',
        }}
      >
        <Box
          sx={{
            bgcolor: '#727D77',
            borderRadius: '4px',
            padding: '1rem',
            width: isSmallSize ? '100%' : '48%'
          }}
        >
          <Typography variant="h5" textAlign="center" fontWeight="bold">
            Users Referred
          </Typography>
          <Typography variant="h4" textAlign="center">
            {listOfUsersReferred.length}
          </Typography>
        </Box>

        <Box
          sx={{
            bgcolor: '#727D77',
            borderRadius: '4px',
            padding: '1rem',
            width: isSmallSize ? '100%' : '48%'
          }}
        >
          <Typography variant="h5" textAlign="center" fontWeight="bold">
            Amount Earned (INC)
          </Typography>
          <Typography variant="h4" textAlign="center">
            {amountEarned}
          </Typography>
        </Box>
      </Box>


      {listOfUsersReferred.length > 0 && <Box>
        <StyledDataGrid
          rows={listOfUsersReferred.map((data, index) => {
            return {
              id: index,
              username: data.username,
              joinDate: data.joinDate,
            };
          })}
          columns={referredUsersTableColumns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 }
            }
          }}
          disableRowSelectionOnClick
        />
      </Box>}
    </Box>
  );
};