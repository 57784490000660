import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { listOfCountries } from '../../constants/listOfCountries';
import { Controller, Control } from 'react-hook-form';
import { SignUpFormType } from '../../types/types';

interface CountrySelectProps {
  control: Control<SignUpFormType, SignUpFormType>;
  onCountryChange: (areaCode: string) => void;  // Add callback for area code
}

const CountrySelect = ({ control, onCountryChange }: CountrySelectProps) => {
  return (
    <Controller
      name="country"
      control={control}
      rules={{
        required: true
      }}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          id="country-select-demo"
          sx={{ width: 'auto', marginTop: '16px', marginBottom: '8px' }}
          options={listOfCountries}
          autoHighlight
          getOptionLabel={(option) => option.label}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              <img
                loading="lazy"
                width="20"
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                alt=""
              />
              {option.label} ({option.code})
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Choose a country"
              inputProps={{
                ...params.inputProps
              }}
              required
            />
          )}
          onChange={(_, item) => {
            onChange(item);
            onCountryChange(item ? item.phone : ''); // Pass area code to parent
          }}
          value={value}
        />
      )}
    />
  );
};

export default CountrySelect;
