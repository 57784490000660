import {
  DashboardIcon,
  PlayIcon,
  CoinIcon,
  ChartIcon,
  SubscriptionsIcon,
  StoreIcon,
  CarouselIcon,
  ShopIcon,
  LeaderboardIcon,
  ExtensionIcon,
  LocalOfferIcon,
  SettingsIcon,
  DescriptionIcon,
  HowToRegIcon
} from './icons';

export const SIDEBAR_DATA = [
  {
    name: 'Dashboard',
    path: '',
    icon: DashboardIcon
  },
  {
    name: 'Play',
    path: 'play',
    icon: PlayIcon
  },
  {
    name: 'Public Presale',
    path: '',
    icon: LocalOfferIcon,
    disabled: true
  },
  {
    name: 'White Paper',
    path: 'white-paper',
    icon: DescriptionIcon
  },
  {
    name: 'Token', // also display token on the dash board
    path: 'token',
    icon: CoinIcon,
    disabled: true
  },
  {
    name: 'Market Watch',
    path: 'marketwatch',
    icon: ChartIcon,
    disabled: true
  },
  // {
  //   name: 'Marketplace', //using opensea
  //   path: 'marketplace',
  //   icon: StoreIcon
  // },
  {
    name: 'NFT Collection',
    path: 'nft',
    icon: CarouselIcon,
    disabled: true
  },
  {
    name: 'Subscription',
    path: 'subscription',
    icon: SubscriptionsIcon,
    disabled: true
  },
  {
    name: 'Giveaway',
    path: 'giveaway',
    icon: ShopIcon,
    disabled: true
  },
  {
    name: 'Leaderboard',
    path: 'leaderboard',
    icon: LeaderboardIcon,
    disabled: true
  },
  {
    name: 'SN',
    path: 'sn',
    icon: ExtensionIcon,
    disabled: true
  },
  {
    name: 'White List',
    path: 'white-list',
    icon: HowToRegIcon,
    disabled: true,
    optionalDisabledText: 'Finished'
  },
  {
    name: 'Settings',
    path: 'settings',
    icon: SettingsIcon
  }
];

// balance on the header, with connect metamask button in the header, (see opensea to see how people connect with metamask), token market price in header

// dashboard, have one that shows balance, graph(graph if the coin went up or down) and current value of user tokens

// faucet as a dashboard item (saying claim faucet orwise show timer for next faucet), claim NFTs dashboard item

// sticky footer that shows faucet stats, registered users, games played, INC won

// first login show modal to claim NFTs

// name, email, country, state, username(referal code), card deatils (change payment details), subscription payment

// subscription page showing other plans, and have table with ticks showing the difference, show your current plan

// build dark mode use colours from Veltrix

//INC dex (think of it as pokedex showing all cards)

// Token page - how much supply

//Exchange token, withdraw and deposit in the token page
// exchange token for subscription or giveaway
