import React from 'react';
import { Box, Typography } from '@mui/material';

type GameMessageScreenProps = { message: string };

export const GameMessageScreen = ({ message }: GameMessageScreenProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: `calc(100vh - 4rem)`
      }}
    >
      <Typography variant="h1" fontWeight="bold">
        {message}
      </Typography>
    </Box>
  );
};
