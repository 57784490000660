import React from 'react';
import { Box } from '@mui/material';
import { MatchHUD, CardBattleArea, HandAndDeck } from '.';
import {
  CardGameOpponentState,
  CardGameUserState,
  WarriorCardDataType,
  TokenCardDataType
} from '../../../../types/types';
import gameBackgroundImage from '../../../../assets/Images/game_background.png';

type MatchBattleScreenProps = {
  username: string;
  userState: CardGameUserState | null;
  opponentUsername: string;
  opponentState: CardGameOpponentState | null;
  selectedCard: WarriorCardDataType | 'noCard' | null;
  opponentSelectedCard: WarriorCardDataType | 'noCard' | null;
  onCardSubmit: () => void;
  roundStartTimer: Date | null;
  countdownTimer: number;
  setCountdownTimer: (value: number) => void;
  hasOpponentDisconnected: boolean;
  setSelectedCard: (card: WarriorCardDataType | 'noCard' | null) => void;
  selectedTokenCard: TokenCardDataType | 'noCard' | null;
  setSelectedTokenCard: (card: TokenCardDataType | 'noCard' | null) => void;
  opponentSelectedTokenCard: TokenCardDataType | 'noCard' | null;
  disableCardSelection: boolean;
  disableCardHoverInfo: boolean;
  setDisbaleCardHoverInfo: (value: boolean) => void;
  playerCardFinalAttack: number | null;
  playerCardFinalDefence: number | null;
  playerBattleDamageTaken: number | null;
  playerEffectDamageTaken: number | null;
  playerHealAmount: number | null;
  opponentCardFinalAttack: number | null;
  opponentCardFinalDefence: number | null;
  opponentBattleDamageTaken: number | null;
  opponentEffectDamageTaken: number | null;
  opponentHealAmount: number | null;
  updatedUserHand: (WarriorCardDataType | TokenCardDataType)[] | null;
  updatedUserDeckCount: number | null;
};

export const MatchBattleScreen = ({
  username,
  userState,
  opponentUsername,
  opponentState,
  selectedCard,
  opponentSelectedCard,
  onCardSubmit,
  roundStartTimer,
  countdownTimer,
  setCountdownTimer,
  hasOpponentDisconnected,
  setSelectedCard,
  selectedTokenCard,
  setSelectedTokenCard,
  opponentSelectedTokenCard,
  disableCardSelection,
  disableCardHoverInfo,
  setDisbaleCardHoverInfo,
  playerCardFinalAttack,
  playerCardFinalDefence,
  playerBattleDamageTaken,
  playerEffectDamageTaken,
  playerHealAmount,
  opponentCardFinalAttack,
  opponentCardFinalDefence,
  opponentBattleDamageTaken,
  opponentEffectDamageTaken,
  opponentHealAmount,
  updatedUserHand,
  updatedUserDeckCount
}: MatchBattleScreenProps) => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${gameBackgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: `calc(100vh - 4rem)`,
        width: '100%'
      }}
    >
      <MatchHUD
        username={username}
        userHp={userState?.hp ?? 500}
        opponentUsername={opponentUsername}
        opponentHp={opponentState?.hp ?? 500}
        roundStartTimer={roundStartTimer}
        countdownTimer={countdownTimer}
        setCountdownTimer={setCountdownTimer}
        hasOpponentDisconnected={hasOpponentDisconnected}
        playerBattleDamageTaken={playerBattleDamageTaken}
        playerEffectDamageTaken={playerEffectDamageTaken}
        playerHealAmount={playerHealAmount}
        opponentBattleDamageTaken={opponentBattleDamageTaken}
        opponentEffectDamageTaken={opponentEffectDamageTaken}
        opponentHealAmount={opponentHealAmount}
      />

      <CardBattleArea
        selectedCard={selectedCard}
        opponentSelectedCard={opponentSelectedCard}
        onCardSubmit={onCardSubmit}
        selectedTokenCard={selectedTokenCard}
        opponentSelectedTokenCard={opponentSelectedTokenCard}
        disableCardSelection={disableCardSelection}
        setSelectedCard={setSelectedCard}
        setSelectedTokenCard={setSelectedTokenCard}
        disableCardHoverInfo={disableCardHoverInfo}
        setDisbaleCardHoverInfo={setDisbaleCardHoverInfo}
        playerCardFinalAttack={playerCardFinalAttack}
        playerCardFinalDefence={playerCardFinalDefence}
        opponentCardFinalAttack={opponentCardFinalAttack}
        opponentCardFinalDefence={opponentCardFinalDefence}
      />

      <HandAndDeck
        usersHand={userState?.hand ?? []}
        usersDeckCount={userState?.deckCount ?? 25}
        selectedCard={selectedCard}
        setSelectedCard={setSelectedCard}
        selectedTokenCard={selectedTokenCard}
        setSelectedTokenCard={setSelectedTokenCard}
        disableCardSelection={disableCardSelection}
        disableCardHoverInfo={disableCardHoverInfo}
        updatedUserHand={updatedUserHand}
        usersGraveyard={userState?.graveyard ?? []}
        updatedUserDeckCount={updatedUserDeckCount}
      />
    </Box>
  );
};
