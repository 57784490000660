import React from 'react';
import { Grid, Paper, Box, Typography, TextField, Button } from '@mui/material';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { TwoFactorAuthValidationFormType } from '../../../types/types';
import { useAuth } from '../../../contexts/AuthContext';
import { useUser } from '../../../contexts/UserContext';

type TwoFactorAuthValidationFormProps = {
  rememberUser: boolean;
  preAccessToken: string;
};

export const TwoFactorAuthValidationForm = ({
  rememberUser,
  preAccessToken
}: TwoFactorAuthValidationFormProps) => {
  const { setAccessToken } = useAuth();
  const { setUser } = useUser()

  const { handleSubmit, control } = useForm<TwoFactorAuthValidationFormType>();

  const onSubmit: SubmitHandler<TwoFactorAuthValidationFormType> = async (
    data
  ) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/otp/validate`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            rememberUser,
            twoFactorToken: data.token,
            preAccessToken
          })
        }
      );

      if (response.ok) {
        const result = await response.json();
        setAccessToken(result.accessToken);
        setUser(result.user)
      } else {
        const errorResult = await response.json();
        console.error('Two Factor Authentication failed:', errorResult.message);
        alert('Two Factor Authentication failed: ' + errorResult.message);
      }
    } catch (error: any) {
      console.error('Error during Two Factor Authentication:', error);
      alert('Two Factor Authentication error: ' + error.message);
    }
  };

  return (
    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
      <Box
        sx={{
          my: 8,
          mx: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Typography component="h1" variant="h4">
          Two Factor Authentication
        </Typography>
        <Box sx={{ mt: 10 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="token"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  label="Enter 2FA Code"
                  id="token"
                  autoFocus
                  {...field}
                />
              )}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Submit
            </Button>
          </form>
        </Box>
      </Box>
    </Grid>
  );
};
