import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './fonts.css';
import CssBaseline from '@mui/material/CssBaseline';
import Home from './features/Home/Home';
import Checkout from './features/Checkout/Checkout';
import Upsell from './features/Upsell/Upsell';
import ThankYou from './features/ThankYou/ThankYou';
import WhitePaper from './components/WhitePaper/WhitePaper';
import { GlobalProvider } from './contexts/GlobalProvider';
import { ProtectedRoute } from './auth/ProtectedRoute';
import { Login } from './features/Login';
import { MemberPortal } from './features/MemberPortal';
import { SignUp } from './features/SignUp';
import { SetPassword } from './features/SetPassword';
import { SnackbarProvider } from 'notistack';

const theme = createTheme({
  palette: {
    mode: 'dark'
  },
  typography: {
    fontFamily: ['Montserrat', 'sans-serif'].join(','),
    allVariants: {
      color: '#ffffff'
    }
  },
  components: {
    MuiTypography: {
      defaultProps: {
        color: 'textPrimary'
      }
    }
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalProvider>
        <SnackbarProvider
          maxSnack={3} // Set maximum number of snackbars displayed at once
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Set the position of the snackbars
        >
          <Routes>
            {/* <Route path="/" element={<Home />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/exclusive-offer" element={<Upsell />} />
            <Route path="/thank-you" element={<ThankYou />} /> */}
            <Route path="/" element={<WhitePaper />} />
            <Route path="/login" element={<Login />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/set-password" element={<SetPassword />} />
            <Route element={<ProtectedRoute />}>
              <Route path="/member-portal/*" element={<MemberPortal />} />
            </Route>
            <Route path="*" element={<Login />} />
          </Routes>
        </SnackbarProvider>
      </GlobalProvider>
    </ThemeProvider>
  );
}

export default App;
