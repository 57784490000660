import { DeckType } from '../types/types';

const alienStarterDeck: DeckType = {
  deck: [
    {
      card: {
        id: '425',
        rarity: 'Common',
        name: 'PEGASUS',
        attack: 79,
        defence: 96,
        owned: 1,
        imgUrl: 'PEGASUS.png',
        faction: 'Alien',
        type: 'Warrior',
        cost: 9,
        abilityName: 'Orbital Beam',
        abilityDescription:
          'Opponent takes 10 effect damage per card in your hand'
      },
      quantity: 2
    },
    {
      card: {
        id: '530',
        rarity: 'Common',
        name: 'SERPENS',
        attack: 60,
        defence: 99,
        owned: 1,
        imgUrl: 'SERPENS.png',
        faction: 'Alien',
        type: 'Warrior',
        cost: 9,
        abilityName: 'Hive Mind',
        abilityDescription:
          'This cards ATK is increased by 300% if there are at least 6 ALIEN faction cards in your hand'
      },
      quantity: 1
    },
    {
      card: {
        id: '480',
        rarity: 'Common',
        name: 'PYRIXIA',
        attack: 67,
        defence: 90,
        owned: 1,
        imgUrl: 'PYRIXIA.png',
        faction: 'Alien',
        type: 'Warrior',
        cost: 9,
        abilityName: 'Interplanet Support',
        abilityDescription: 'Draw an additional card next turn'
      },
      quantity: 3
    },
    {
      card: {
        id: '650',
        rarity: 'Common',
        name: 'VELKA',
        attack: 31,
        defence: 25,
        owned: 1,
        imgUrl: 'VELKA.png',
        faction: 'Alien',
        type: 'Warrior',
        cost: 9,
        abilityName: 'Terraform',
        abilityDescription:
          'Discard 2 random cards from your hand to deal 100 effect damage to the opponent'
      },
      quantity: 2
    },
    {
      card: {
        id: '365',
        rarity: 'Common',
        name: 'NEBULA',
        attack: 47,
        defence: 99,
        owned: 1,
        imgUrl: 'NEBULA.png',
        faction: 'Alien',
        type: 'Warrior',
        cost: 9,
        abilityName: 'Kinetic Forcefield',
        abilityDescription:
          "If this card's DEF is higher than Opponents card's ATK, deal battle damage to the opponent by the difference"
      },
      quantity: 2
    },
    {
      card: {
        id: '410',
        rarity: 'Common',
        name: 'OMNIOUS',
        attack: 29,
        defence: 34,
        owned: 1,
        imgUrl: 'OMNIOUS.png',
        faction: 'Alien',
        type: 'Warrior',
        cost: 9,
        abilityName: 'Mind Link',
        abilityDescription: 'Heal by 20 per card in your hand'
      },
      quantity: 2
    },
    {
      card: {
        id: '415',
        rarity: 'Common',
        name: 'ORION',
        attack: 87,
        defence: 92,
        owned: 1,
        imgUrl: 'ORION.png',
        faction: 'Alien',
        type: 'Warrior',
        cost: 9,
        abilityName: 'Xeno Burst',
        abilityDescription:
          'Opponent takes 50% of this cards ATK as effect damage if you have 3 or less cards in your hand'
      },
      quantity: 2
    },
    {
      card: {
        id: '690',
        rarity: 'Common',
        name: 'VYSTRIS',
        attack: 75,
        defence: 56,
        owned: 1,
        imgUrl: 'VYSTRIS.png',
        faction: 'Alien',
        type: 'Warrior',
        cost: 9,
        abilityName: 'Ray Gun',
        abilityDescription:
          'This cards ATK is increased by 10% per card in hand'
      },
      quantity: 2
    },
    {
      card: {
        id: '640',
        rarity: 'Common',
        name: 'URSA',
        attack: 89,
        defence: 44,
        owned: 1,
        imgUrl: 'URSA.png',
        faction: 'Alien',
        type: 'Warrior',
        cost: 9,
        abilityName: 'Psychic Energy',
        abilityDescription:
          'Reduce Opponents card DEF by 10% per card in your hand and increase this cards DEF by the amount reduced'
      },
      quantity: 2
    },
    {
      card: {
        id: '1f73fc30-abcd-4476-89d1-0637370dc7db',
        rarity: 'Common',
        name: 'BTC',
        owned: 5,
        imgUrl: 'BTC.png',
        faction: 'Alien',
        factionBuff: 10,
        type: 'Token',
        cost: 3
      },
      quantity: 3
    }
  ],
  deckName: 'Alien Starter Deck'
};

const cyborgStarterDeck: DeckType = {
  deck: [
    {
      card: {
        id: '710',
        rarity: 'Common',
        name: 'XION',
        attack: 63,
        defence: 58,
        owned: 1,
        imgUrl: 'XION.png',
        faction: 'Cyborg',
        type: 'Warrior',
        cost: 9,
        abilityName: 'Overheat',
        abilityDescription:
          'Opponent takes 50 effect damage per Token card in your hand'
      },
      quantity: 2
    },
    {
      card: {
        id: '320',
        rarity: 'Common',
        name: 'LUXOR',
        attack: 69,
        defence: 51,
        owned: 1,
        imgUrl: 'LUXOR.png',
        faction: 'Cyborg',
        type: 'Warrior',
        cost: 9,
        abilityName: 'Spare Power Source',
        abilityDescription:
          'Apply a random Token effect card in your hand to this card'
      },
      quantity: 2
    },
    {
      card: {
        id: '505',
        rarity: 'Common',
        name: 'RAINE',
        attack: 70,
        defence: 54,
        owned: 1,
        imgUrl: 'RAINE.png',
        faction: 'Cyborg',
        type: 'Warrior',
        cost: 9,
        abilityName: 'Power Drain',
        abilityDescription:
          'Token applied to this card also reduced Opponents Card stats by the same percentage'
      },
      quantity: 2
    },
    {
      card: {
        id: '670',
        rarity: 'Common',
        name: 'VO',
        attack: 38,
        defence: 33,
        owned: 1,
        imgUrl: 'VO.png',
        faction: 'Cyborg',
        type: 'Warrior',
        cost: 9,
        abilityName: 'Emergency Power',
        abilityDescription:
          'Heal 100 HP if you have played a Token card this turn'
      },
      quantity: 1
    },
    {
      card: {
        id: '705',
        rarity: 'Common',
        name: 'XENOLITH',
        attack: 60,
        defence: 86,
        owned: 1,
        imgUrl: 'XENOLITH.png',
        faction: 'Cyborg',
        type: 'Warrior',
        cost: 9,
        abilityName: 'Afterburn',
        abilityDescription:
          'Remove a card from opponents hand if you have played a Token card this turn'
      },
      quantity: 2
    },
    {
      card: {
        id: '310',
        rarity: 'Common',
        name: 'LUMOS',
        attack: 65,
        defence: 54,
        owned: 1,
        imgUrl: 'LUMOS.png',
        faction: 'Cyborg',
        type: 'Warrior',
        cost: 9,
        abilityName: 'Power Efficiency',
        abilityDescription:
          'Increase ATK of this card by 10% per Token in your graveyard'
      },
      quantity: 2
    },
    {
      card: {
        id: '190',
        rarity: 'Common',
        name: 'GONDEMAR',
        attack: 70,
        defence: 84,
        owned: 1,
        imgUrl: 'GONDEMAR.png',
        faction: 'Cyborg',
        type: 'Warrior',
        cost: 9,
        abilityName: 'Recycled Core',
        abilityDescription:
          'If a Token was used by the player this turn it goes back into the players hand'
      },
      quantity: 3
    },
    {
      card: {
        id: '655',
        rarity: 'Common',
        name: 'VEX',
        attack: 58,
        defence: 54,
        owned: 1,
        imgUrl: 'VEX.png',
        faction: 'Cyborg',
        type: 'Warrior',
        cost: 9,
        abilityName: 'Backup Power',
        abilityDescription:
          'Player draws an additional card next turn if the player has used a Token card this turn'
      },
      quantity: 3
    },
    {
      card: {
        id: '1f73fc30-abcd-4476-89d1-0637370dc7db',
        rarity: 'Common',
        name: 'ASI',
        owned: 5,
        imgUrl: 'ASI.png',
        faction: 'Cyborg',
        factionBuff: 10,
        type: 'Token',
        cost: 3
      },
      quantity: 9
    }
  ],
  deckName: 'Cyborg Starter Deck'
};

const dragonStarterDeck: DeckType = {
  deck: [
    {
      card: {
        id: '710',
        rarity: 'Common',
        name: 'XION',
        attack: 63,
        defence: 58,
        owned: 1,
        imgUrl: 'XION.png',
        faction: 'Cyborg',
        type: 'Warrior',
        cost: 9,
        abilityName: 'Overheat',
        abilityDescription:
          'Opponent takes 50 effect damage per Token card in your hand'
      },
      quantity: 2
    },
    {
      card: {
        id: '1f73fc30-abcd-4476-89d1-0637370dc7db',
        rarity: 'Common',
        name: 'ETH',
        owned: 5,
        imgUrl: 'ETH.png',
        faction: 'Dragon',
        factionBuff: 10,
        type: 'Token',
        cost: 3
      },
      quantity: 3
    }
  ],
  deckName: 'Dragon Starter Deck'
};

const humanStarterDeck: DeckType = {
  deck: [
    {
      card: {
        id: '110',
        rarity: 'Common',
        name: 'DEFENDER',
        attack: 33,
        defence: 100,
        owned: 1,
        imgUrl: 'DEFENDER.png',
        faction: 'Human',
        type: 'Warrior',
        cost: 9,
        abilityName: 'Spiked Shield',
        abilityDescription:
          "If this card's DEF is higher than Opponents card's ATK, deal battle damage to the opponent by the difference"
      },
      quantity: 2
    },
    {
      card: {
        id: '205',
        rarity: 'Common',
        name: 'HACKED ASSASSIN',
        attack: 41,
        defence: 32,
        owned: 1,
        imgUrl: 'HACKED ASSASSIN.png',
        faction: 'Human',
        type: 'Warrior',
        cost: 9,
        abilityName: 'Backstab',
        abilityDescription: "Remove a card from opponent's hand"
      },
      quantity: 2
    },
    {
      card: {
        id: '345',
        rarity: 'Common',
        name: 'MO',
        attack: 79,
        defence: 96,
        owned: 1,
        imgUrl: 'MO.png',
        faction: 'Human',
        type: 'Warrior',
        cost: 9,
        abilityName: 'Coin Toss',
        abilityDescription:
          "65% chance for player to draw an extra card next turn or 35% chance to remove a random card from player's hand"
      },
      quantity: 2
    },
    {
      card: {
        id: '565',
        rarity: 'Common',
        name: 'STRIKER',
        attack: 88,
        defence: 85,
        owned: 1,
        imgUrl: 'STRIKER.png',
        faction: 'Human',
        type: 'Warrior',
        cost: 9,
        abilityName: 'Armour Piercing Round',
        abilityDescription:
          "This card's ATK is reduced by 35% and ignores opponent's card DEF"
      },
      quantity: 1
    },
    {
      card: {
        id: '285',
        rarity: 'Common',
        name: 'LAUNCH',
        attack: 75,
        defence: 48,
        owned: 1,
        imgUrl: 'LAUNCH.png',
        faction: 'Human',
        type: 'Warrior',
        cost: 9,
        abilityName: 'Explosive Shrapnel',
        abilityDescription:
          "Opponent takes 35% of this card's ATK as effect damage"
      },
      quantity: 2
    },
    {
      card: {
        id: '300',
        rarity: 'Common',
        name: 'LUCIA',
        attack: 74,
        defence: 51,
        owned: 1,
        imgUrl: 'LUCIA.png',
        faction: 'Human',
        type: 'Warrior',
        cost: 9,
        abilityName: 'Healing Magic',
        abilityDescription:
          "Heal by 25% of this card's base ATK and DEF combined"
      },
      quantity: 2
    },
    {
      card: {
        id: '305',
        rarity: 'Common',
        name: 'LUMEN',
        attack: 94,
        defence: 47,
        owned: 1,
        imgUrl: 'LUMEN.png',
        faction: 'Human',
        type: 'Warrior',
        cost: 9,
        abilityName: "Maiden's Gift",
        abilityDescription: 'Draw an additional card next turn'
      },
      quantity: 2
    },
    {
      card: {
        id: '405',
        rarity: 'Common',
        name: 'OLAH',
        attack: 84,
        defence: 51,
        owned: 1,
        imgUrl: 'OLAH.png',
        faction: 'Human',
        type: 'Warrior',
        cost: 9,
        abilityName: 'Vanguard',
        abilityDescription:
          "This card's stats are increased by 5% per card in player's hand"
      },
      quantity: 3
    },
    {
      card: {
        id: '470',
        rarity: 'Common',
        name: 'PRISMYX',
        attack: 83,
        defence: 66,
        owned: 1,
        imgUrl: 'PRISMYX.png',
        faction: 'Human',
        type: 'Warrior',
        cost: 9,
        abilityName: 'Avenger',
        abilityDescription:
          "This card's ATK increased by 10% per HUMAN faction card in the player's graveyard"
      },
      quantity: 2
    },
    {
      card: {
        id: '1f73fc30-abcd-4476-89d1-0637370dc7db',
        rarity: 'Common',
        name: 'SOL',
        owned: 5,
        imgUrl: 'SOL.png',
        faction: 'Human',
        factionBuff: 10,
        type: 'Token',
        cost: 3
      },
      quantity: 3
    }
  ],
  deckName: 'Human Starter Deck'
};

const hybridStarterDeck: DeckType = {
  deck: [
    {
      card: {
        id: '110',
        rarity: 'Common',
        name: 'DEFENDER',
        attack: 33,
        defence: 100,
        owned: 1,
        imgUrl: 'DEFENDER.png',
        faction: 'Human',
        type: 'Warrior',
        cost: 9,
        abilityName: 'Spiked Shield',
        abilityDescription:
          "If this card's DEF is higher than Opponents card's ATK, deal battle damage to the opponent by the difference"
      },
      quantity: 2
    },
    {
      card: {
        id: '1f73fc30-abcd-4476-89d1-0637370dc7db',
        rarity: 'Common',
        name: 'DOGE',
        owned: 5,
        imgUrl: 'DOGE.png',
        faction: 'Hybrid',
        factionBuff: 10,
        type: 'Token',
        cost: 3
      },
      quantity: 3
    }
  ],
  deckName: 'Hybrid Starter Deck'
};

const mechStarterDeck: DeckType = {
  deck: [
    {
      card: {
        id: '60',
        rarity: 'Common',
        name: 'AYNE3',
        attack: 54,
        defence: 98,
        owned: 1,
        imgUrl: 'AYNE3.png',
        faction: 'Mech',
        type: 'Warrior',
        cost: 9,
        abilityName: 'Titanium Alloy',
        abilityDescription:
          "If this card's DEF is higher than Opponents card's ATK, deal battle damage to the opponent by the difference"
      },
      quantity: 2
    },
    {
      card: {
        id: '95',
        rarity: 'Common',
        name: 'CERBERUS',
        attack: 69,
        defence: 66,
        owned: 1,
        imgUrl: 'CERBERUS.png',
        faction: 'Mech',
        type: 'Warrior',
        cost: 9,
        abilityName: 'Unstable Fusion',
        abilityDescription:
          "Randomly remove a card from player's hand, if removed card is a monster card increase this card's stats by the stats of the removed card"
      },
      quantity: 3
    },
    {
      card: {
        id: '490',
        rarity: 'Common',
        name: 'QUANTUM',
        attack: 21,
        defence: 83,
        owned: 1,
        imgUrl: 'QUANTUM.png',
        faction: 'Mech',
        type: 'Warrior',
        cost: 9,
        abilityName: 'Recycler',
        abilityDescription: 'Heal player by 8 per card in the graveyard'
      },
      quantity: 2
    },
    {
      card: {
        id: '375',
        rarity: 'Common',
        name: 'NEU',
        attack: 23,
        defence: 44,
        owned: 1,
        imgUrl: 'NEU.png',
        faction: 'Mech',
        type: 'Warrior',
        cost: 9,
        abilityName: 'Self Destruct',
        abilityDescription:
          'If this card was removed from hand by a card effect, deal 100 damage to opponent'
      },
      quantity: 1
    },
    {
      card: {
        id: '135',
        rarity: 'Common',
        name: 'DREXUS',
        attack: 77,
        defence: 70,
        owned: 1,
        imgUrl: 'DREXUS.png',
        faction: 'Mech',
        type: 'Warrior',
        cost: 9,
        abilityName: 'Scrapyard',
        abilityDescription:
          "This card's ATK is increased by 5% per MECH faction card in your graveyard"
      },
      quantity: 3
    },
    {
      card: {
        id: '635',
        rarity: 'Common',
        name: 'TRON',
        attack: 82,
        defence: 49,
        owned: 1,
        imgUrl: 'TRON.png',
        faction: 'Mech',
        type: 'Warrior',
        cost: 9,
        abilityName: 'Welded Plates',
        abilityDescription:
          "This card's DEF is increased by 5% per MECH faction card in your graveyard"
      },
      quantity: 3
    },
    {
      card: {
        id: '245',
        rarity: 'Common',
        name: 'INSENER',
        attack: 46,
        defence: 31,
        owned: 1,
        imgUrl: 'INSENER.png',
        faction: 'Mech',
        type: 'Warrior',
        cost: 9,
        abilityName: 'Revenge of the Fallen',
        abilityDescription:
          "Opponent takes 8 effect damage per card in the player's graveyard"
      },
      quantity: 3
    },
    {
      card: {
        id: '630',
        rarity: 'Common',
        name: 'TRION',
        attack: 48,
        defence: 43,
        owned: 1,
        imgUrl: 'TRION.png',
        faction: 'Mech',
        type: 'Warrior',
        cost: 9,
        abilityName: 'Scrapper',
        abilityDescription:
          'Remove the top 2 cards from your deck to the graveyard'
      },
      quantity: 2
    },
    {
      card: {
        id: '675',
        rarity: 'Common',
        name: 'VOLT',
        attack: 75,
        defence: 75,
        owned: 1,
        imgUrl: 'VOLT.png',
        faction: 'Mech',
        type: 'Warrior',
        cost: 9,
        abilityName: 'Reboot',
        abilityDescription:
          'Remove a random card from your hand, and draw an additional card next turn'
      },
      quantity: 2
    },
    {
      card: {
        id: '335',
        rarity: 'Common',
        name: 'MECH MAID',
        attack: 48,
        defence: 23,
        owned: 1,
        imgUrl: 'MECH MAID.png',
        faction: 'Mech',
        type: 'Warrior',
        cost: 9,
        abilityName: 'Assembly Line',
        abilityDescription: 'Draw an additional card next turn'
      },
      quantity: 2
    },
    {
      card: {
        id: '1f73fc30-abcd-4476-89d1-0637370dc7db',
        rarity: 'Common',
        name: 'BNB',
        owned: 5,
        imgUrl: 'BNB.png',
        faction: 'Mech',
        factionBuff: 10,
        type: 'Token',
        cost: 3
      },
      quantity: 6
    }
  ],
  deckName: 'Mech Starter Deck'
};

export const betaStarterDecks: DeckType[] = [
  alienStarterDeck,
  cyborgStarterDeck,
  // dragonStarterDeck,
  humanStarterDeck,
  // hybridStarterDeck,
  mechStarterDeck
];
