import React, { useEffect, useState } from 'react';
import {
  Box,
  Tabs,
  Tab,
  Select,
  MenuItem,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { useLocation } from 'react-router-dom';

import {
  SettingsUserDetails,
  SettingsSecurity,
  SettingsUserSubscription,
  SettingsUserReferrals,
  SettingsUserPayments,
  SettingsUserProfileSettings
} from './components';

export const UserSettings = () => {
  const { search } = useLocation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const getInitialTab = () => (search === '?referral=true' ? 3 : 0);
  const [selectedTab, setSelectedTab] = useState<number>(getInitialTab());

  useEffect(() => {
    if (isSmallScreen) return setSelectedTab(-1);
  }, [isSmallScreen]);

  return (
    <Box sx={{ p: '2rem', display: 'flex', justifyContent: 'space-between' }}>
      {!isSmallScreen && (
        <Box sx={{ width: '30%', paddingRight: 2 }}>
          <SettingsUserProfileSettings />
        </Box>
      )}

      <Box sx={{ width: isSmallScreen ? '100%' : '70%' }}>
        <Box
          sx={{
            backgroundColor: 'divider',
            borderRadius: '4px',
            height: '100%'
          }}
        >
          {isSmallScreen ? (
            <Select
              value={selectedTab}
              onChange={(e) => setSelectedTab(e.target.value as number)}
              fullWidth
              sx={{ marginBottom: 2 }}
            >
              <MenuItem value={-1}>Profile Settings</MenuItem>
              <MenuItem value={0}>User Details</MenuItem>
              <MenuItem value={1}>Security</MenuItem>
              <MenuItem value={2}>Referrals</MenuItem>
              {/* <MenuItem value={2}>Subscription</MenuItem> */}
              {/* <MenuItem value={4}>Payments</MenuItem> */}
            </Select>
          ) : (
            <Tabs
              value={selectedTab}
              onChange={(_, tabIndex) => setSelectedTab(tabIndex)}
              variant="scrollable"
              orientation="horizontal"
              sx={{ borderBottom: 1, borderColor: 'divider' }}
            >
              <Tab label="User Details" />
              <Tab label="Security" />
              {/* <Tab label="Subscription" /> */}
              <Tab label="Referrals" />
              {/* <Tab label="Payments" /> */}
            </Tabs>
          )}

          <Box sx={{ padding: '1rem' }}>
            {isSmallScreen && selectedTab === -1 && (
              <SettingsUserProfileSettings />
            )}
            {selectedTab === 0 && <SettingsUserDetails />}
            {selectedTab === 1 && <SettingsSecurity />}
            {selectedTab === 2 && (
              <SettingsUserReferrals isSmallSize={isSmallScreen} />
            )}
            {/* {selectedTab === 2 && <SettingsUserSubscription />} */}
            {/* {selectedTab === 4 && <SettingsUserPayments />} */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
