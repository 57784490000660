import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import { useUser } from '../../../../contexts/UserContext';
type ReferralModalProps = {
  isOpen: boolean;
  handleClose: () => void;
};

const boxStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '2rem',
  backgroundColor: 'white',
  margin: '24px',
  top: '50%',
  left: '50%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  borderRadius: '4px',
  p: 4,
  fontSize: '1rem',
};

export const ReferralModal: React.FC<ReferralModalProps> = ({
  isOpen,
  handleClose,
}) => {
  const { user } = useUser()
  return (
    <Modal open={isOpen} onClose={handleClose} sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <Box sx={boxStyle}>
        <Typography variant="h6" textAlign="center" gutterBottom>
          The more you refer the more you earn!<br/>
          Earn 10% of your referrals activities!<br/>
          Referral Code: {user.referralCode}
        </Typography>
        <Button sx={{ mt: 2, alignSelf: 'center' }} variant="contained" onClick={handleClose}>
          Close
        </Button>
      </Box>
    </Modal>
  )
};
