import { useAuth } from '../contexts/AuthContext';
import axios, { AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { useEffect } from 'react';

// Extend AxiosRequestConfig to include _requiresAuth
interface CustomAxiosRequestConfig<D = any> extends InternalAxiosRequestConfig<D> {
  _requiresAuth?: boolean;
  _retry?: boolean;
}

export const useNetwork = () => {
  const { accessToken, setAccessToken, logout } = useAuth();
  const apiUrl = process.env.REACT_APP_API_URL;

  // Create an Axios instance
  const api = axios.create({
    baseURL: apiUrl,
    withCredentials: true,
  });

  let latestAccessToken = accessToken;

  useEffect(() => {
    latestAccessToken = accessToken;
  }, [accessToken]);

  useEffect(() => {
    api.interceptors.request.use(
      (config: CustomAxiosRequestConfig) => {
        // Ensure headers are defined and typed correctly
        config.headers = config.headers || {};
        
        if (config._requiresAuth && latestAccessToken) {
          config.headers['Authorization'] = `Bearer ${latestAccessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );
  }, [latestAccessToken]);

  const refreshAccessToken = async () => {
    try {
      const response = await api.post('/auth/refresh-token');
      const newAccessToken = response.data.accessToken;
      setAccessToken(newAccessToken);
      latestAccessToken = newAccessToken;
      return newAccessToken;
    } catch (error) {
      console.error('Error refreshing access token', error);
      logout();
      throw error;
    }
  };

  useEffect(() => {
    const responseInterceptor = api.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config as CustomAxiosRequestConfig;

        if (error.response?.status === 401 && !originalRequest._retry && originalRequest._requiresAuth) {
          originalRequest._retry = true;

          try {
            const newAccessToken = await refreshAccessToken();
            originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
            return api(originalRequest);
          } catch (refreshError) {
            return Promise.reject(refreshError);
          }
        }

        return Promise.reject(error);
      }
    );

    return () => {
      api.interceptors.response.eject(responseInterceptor);
    };
  }, [refreshAccessToken]);

  const request = async (
    endpoint: string,
    method: AxiosRequestConfig['method'],
    body?: any,
    auth: boolean = false
  ) => {
    try {
      const response = await api({
        url: endpoint,
        method,
        data: body,
        headers: auth && latestAccessToken ? { 'Authorization': `Bearer ${latestAccessToken}` } : {},
        _requiresAuth: auth,
      } as CustomAxiosRequestConfig);
      return response.data;
    } catch (error) {
      console.error('API request error:', error);
      throw error;
    }
  };

  const get = (endpoint: string, auth: boolean = false) => request(endpoint, 'GET', undefined, auth);
  const post = (endpoint: string, body: any, auth: boolean = false) => request(endpoint, 'POST', body, auth);
  const put = (endpoint: string, body: any, auth: boolean = false) => request(endpoint, 'PUT', body, auth);
  const del = (endpoint: string, auth: boolean = false) => request(endpoint, 'DELETE', undefined, auth);

  return { get, post, put, del };
};
