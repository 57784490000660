import { useSnackbar } from "notistack";

type SnackbarStatus = 'success' | 'error' | 'warning' | 'info' | 'default';

export const useShowSnackbar = () => {
  const { enqueueSnackbar } = useSnackbar();

  const showSnackbar =  (message: string, status: SnackbarStatus = 'default', duration: number = 4000) => {
    enqueueSnackbar(message, {
      variant: status !== 'default' ? status : undefined,
      autoHideDuration: duration,
    });
  };

  return { showSnackbar };
};
