import React, { useEffect, useMemo, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Home } from './Home';
import { Play } from './Play';
import { MarketWatch } from './MarketWatch';
import { NFTCollection } from './NFTCollection';
import { Subscription } from './Subscription';
import { TokenStats } from './TokenStats';
import { UserSettings } from './UserSettings';
import { Giveaway } from './Giveaway';
import { LiveCoinWatchCoinDataType } from '../../types/types';
import { add } from 'date-fns';
import './MemberPortal.css';
import '@solana/wallet-adapter-react-ui/styles.css';
import { PurchaseModal, usePurchaseModal } from './PurchaseModal';
import MenuBar from './MenuBar/MenuBar';
import { WhitePaper } from './WhitePaper';
import { SocketProvider } from '../../context/SocketContext';
import { useUser } from '../../contexts/UserContext';
import { DiscordPromo } from './DiscordPromo';
import { WhitePaperDoc } from './WhitePaperDoc';
import {
  ConnectionProvider,
  WalletProvider
} from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { clusterApiUrl } from '@solana/web3.js';
import { GiveawayEligibleModal } from './Home/components/GiveawayEligibleModal';
import { DiscordNameAirdrop } from './Home/components/DiscordNameAirdrop';

export const MemberPortal = () => {
  const { user } = useUser();

  const network = WalletAdapterNetwork.Mainnet;
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);
  const wallets = useMemo(() => [], [network]);
  const [top10Coins, setTop10Coins] = useState<
    LiveCoinWatchCoinDataType[] | null
  >(null);

  const [top10CoinsHistory, setTop10CoinsHistory] = useState<
    LiveCoinWatchCoinDataType[] | null
  >(null);

  const [isGiveawayEligibleModalOpen, setIsGiveawayEligibleModalOpen] =
    useState<boolean>(user.isGiveawayEligible === null);

  const handleGiveawayEligibleModalClose = () => {
    setIsGiveawayEligibleModalOpen(false);
  };

  const {
    isBuyModalOpen,
    selectedPurchase,
    handlePurchaseModalClose,
    handlePurchaseSelection
  } = usePurchaseModal();

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <div className="member-portal">
            <meta
              name="viewport"
              content="width=device-width, maximum-scale=1.0"
            />
            <MenuBar>
              <PurchaseModal
                isModalOpen={isBuyModalOpen}
                handlePurchaseModalClose={handlePurchaseModalClose}
                selectedPurchase={selectedPurchase}
              />
              {isGiveawayEligibleModalOpen && (
                <GiveawayEligibleModal
                  isOpen={isGiveawayEligibleModalOpen}
                  handleClose={handleGiveawayEligibleModalClose}
                />
              )}
              <Routes>
                <Route path="/discord-promo" element={<DiscordPromo />} />
                <Route path="/" element={<Home />} />
                <Route
                  path="/play"
                  element={
                    <SocketProvider username={user.username}>
                      <Play />
                    </SocketProvider>
                  }
                />
                {/* <Route
          path="/marketwatch"
          element={
            <MarketWatch
              top10Coins={top10Coins}
              setTop10Coins={setTop10Coins}
              top10CoinsHistory={top10CoinsHistory}
              setTop10CoinsHistory={setTop10CoinsHistory}
            />
          }
        />
        <Route path="/nft" element={<NFTCollection />} />
        <Route
          path="/subscription"
          element={
            <Subscription handlePurchaseSelection={handlePurchaseSelection} />
          }
        />
        <Route
          path="/token"
          element={
            <TokenStats
              IncChartData={
                top10CoinsHistory ? top10CoinsHistory[0].history : []
              }
            />
          }
        /> */}
                <Route path="/settings" element={<UserSettings />} />
                {/* <Route
          path="/giveaway"
          element={
            <Giveaway handlePurchaseSelection={handlePurchaseSelection} />
          }
        /> */}
                {/* <Route path="/white-list" element={<WhitePaper />} /> */}
                <Route path="/white-paper" element={<WhitePaperDoc />} />
                <Route path="/a1rdr0pT3st" element={<DiscordNameAirdrop />} />
              </Routes>
            </MenuBar>
          </div>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};
