import React, { useState } from 'react';
import { Box, Typography, Button, TextField } from '@mui/material';
import { useUser } from '../../../../contexts/UserContext';
import { useNetwork } from '../../../../utils/network';
import { useShowSnackbar } from '../../../../utils/snackbar';
import { publicIp } from 'public-ip';
import discordInfo from '../../../../assets/Images/discord_info.png'
type DiscordNameAirdropProps = {};

export const DiscordNameAirdrop = (props: DiscordNameAirdropProps) => {
  const { user, setUser } = useUser();
  const { showSnackbar } = useShowSnackbar();
  const { post } = useNetwork();
  const [userDiscordName, setUserDiscordName] = useState<string>('');

  const claim5000Airdrop = async () => {
    try {
      const response = await post(
        '/user/claimIncryptDiscord5000Airdrop',
        {
          discordName: userDiscordName,
          ipAddress: await publicIp()
         },
        true
      );
      const { tokenAmount, discordName } = response;

      setUser({
        ...user,
        tokenAmount: tokenAmount,
        discordName,
        hasClaimed5000Airdrop: true,
      });
      showSnackbar('Congratulations, your 5,000 tokens are shown above in your INC balance!', 'success')
    } catch (error: any) {
      showSnackbar(error.response.data.message, 'error');
    }
  }

  return (
    <Box
      sx={{
        backgroundColor: 'divider',
        width: '100%',
        borderRadius: '4px'
      }}
    >
      <Typography
        variant="h4"
        fontWeight="bold"
        textAlign="center"
        sx={{ borderBottom: '1px solid #212121' }}
      >
        Claim the INCRYPT Discord 5,000 Token Airdrop
      </Typography>

      {!user.hasClaimed5000Airdrop && (
        <Box sx={{ px: '1rem' }}>
          <Typography
            variant="body1"
            fontWeight="bold"
            textAlign="center"
            sx={{ mb: '1rem' }}
          >
            Enter your Discord Name to see if you are eligible for the airdrop Snapshot
          </Typography>
          <img src={discordInfo} alt="Discord Info" style={{ maxWidth: '300px', height: 'auto' }} />

          <Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignContent: 'center',
                pt: '1rem'
              }}
            >
              <TextField
                label="Enter Discord Name"
                variant="outlined"
                onChange={(e) => setUserDiscordName(e.target.value)}
                sx={{ mb: '1rem' }}
              />
              <Button
                variant="contained"
                disabled={userDiscordName === ''}
                onClick={claim5000Airdrop}
                sx={{ mb: '1rem' }}
              >
                Check and Claim
              </Button>
            </Box>
          </Box>
        </Box>
      )}
      {user.hasClaimed5000Airdrop && (
        <Box>
          <Typography variant="h6" textAlign="center">
            This account has already claimed the airdrop
          </Typography>
        </Box>
      )}
    </Box>
  );
};
