import React, { useState } from 'react';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { Controller, useForm, SubmitHandler } from 'react-hook-form';
import {
  SettingsDisableTwoFactorAuthFormType,
  SettingsPasswordResetFormType
} from '../../../../types/types';
import { TwoFactorAuthModal } from '../../TwoFactorAuth/TwoFactorAuthModal'
import { useUser } from '../../../../contexts/UserContext';
import { useNetwork } from '../../../../utils/network';
import { useShowSnackbar } from '../../../../utils/snackbar';

export const SettingsSecurity = () => {
  const { user, setUser } = useUser()
  const { post, put } = useNetwork()
  const { showSnackbar } = useShowSnackbar();

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset: resetPassword
  } = useForm<SettingsPasswordResetFormType>();

  const {
    handleSubmit: handleDisableTwoFactorSubmit,
    control: disableTwoFactorControl,
    formState: { errors: disableTwoFactorErrors },
    reset: reset2FA
  } = useForm<SettingsDisableTwoFactorAuthFormType>();

  const [isTwoFAModalOpen, setIsTwoFAModalOpen] = useState<boolean>(false);

  const onSubmit: SubmitHandler<SettingsPasswordResetFormType> = async (
    data: SettingsPasswordResetFormType
  ) => {
    try {
      const result = await put(
        '/user/password',
        {
          currentPassword: data.currentPassword,
          newPassword: data.newPassword,
          confirmPassword: data.confirmPassword,
         },
        true,
      )
      resetPassword({
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      });
      showSnackbar(result.message, 'success')
    } catch (error: any) {
      showSnackbar(error.response.data.message, 'error');
    }
  };

  const disableTwoFactorAuth = async (password: string) => {
    try {
      const result = await post('/user/otp/disable',{ password }, true)
      setUser({
        ...user,
        hasTwoFactorAuthEnabled: false,
      })
      reset2FA({
        password: ''
      })
      showSnackbar(result.message, 'success')
    } catch (error: any) {
      showSnackbar(error.response.data.message, 'error');
    }
  };

  const onDisableTwoFactorSubmit: SubmitHandler<
    SettingsDisableTwoFactorAuthFormType
  > = (data: SettingsDisableTwoFactorAuthFormType) => {
    disableTwoFactorAuth(data.password);
  };

  const handleTwoFAModalClose = () => {
    setIsTwoFAModalOpen(false);
  };

  return (
    <Box
      sx={{
        margin: '0 1rem 0 1rem',
      }}
    >
      <Box>
        <Typography
          variant="h4"
          textAlign="center"
          fontWeight="bold"
          sx={{ pb: '1rem' }}
        >
          Change Password
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            <Controller
              name="currentPassword"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  label="Current Password"
                  type="password"
                  id="currentPassword"
                  error={Boolean(errors.currentPassword)}
                  helperText={
                    errors.currentPassword &&
                    'Please enter your current password'
                  }
                  {...field}
                />
              )}
            />

            <Controller
              name="newPassword"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  label="New Password"
                  type="password"
                  id="newPassword"
                  error={Boolean(errors.confirmPassword)}
                  helperText={
                    errors.confirmPassword && 'Passwords do not match'
                  }
                  {...field}
                />
              )}
            />

            <Controller
              name="confirmPassword"
              control={control}
              rules={{
                required: true
              }}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  label="Confirm Password"
                  type="password"
                  id="confirmPassword"
                  error={Boolean(errors.confirmPassword)}
                  helperText={
                    errors.confirmPassword && 'Passwords do not match'
                  }
                  {...field}
                />
              )}
            />

            <Button variant="contained" type="submit">
              Change password
            </Button>
          </Stack>
        </form>
      </Box>

      <Box>
        <Typography
          variant="h4"
          textAlign="center"
          fontWeight="bold"
          sx={{ pt: '2rem', pb: '1rem' }}
        >
          Two Factor Authentication (2FA)
        </Typography>

        {!user.hasTwoFactorAuthEnabled ? (
          <Box>
            <Typography variant="body1" textAlign="center" sx={{ pb: '1rem' }}>
              Enabling 2FA will add extra security and it make it more safe from
              others trying to access your account.
            </Typography>
            <Button
              variant="contained"
              fullWidth
              onClick={() => setIsTwoFAModalOpen(true)}
            >
              Enable 2FA
            </Button>
          </Box>
        ) : (
          <Box>
            <Typography variant="body1" textAlign="center" sx={{ pb: '1rem' }}>
              2FA is enabled, to disable enter password and click on button
              below
            </Typography>
            <form
              onSubmit={handleDisableTwoFactorSubmit(onDisableTwoFactorSubmit)}
            >
              <Stack spacing={2}>
                <Controller
                  name="password"
                  control={disableTwoFactorControl}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      label="Enter Password"
                      type="password"
                      id="password"
                      autoFocus
                      error={Boolean(disableTwoFactorErrors.password)}
                      helperText={
                        disableTwoFactorErrors.password &&
                        'Please enter your password'
                      }
                      {...field}
                    />
                  )}
                />
                <Button
                  variant="contained"
                  fullWidth
                  color="error"
                  type="submit"
                >
                  Disable 2FA
                </Button>
              </Stack>
            </form>
          </Box>
        )}
      </Box>

      {isTwoFAModalOpen && (
        <TwoFactorAuthModal
          isTwoFAModalOpen={isTwoFAModalOpen}
          handleTwoFAModalClose={handleTwoFAModalClose}
        />
      )}
    </Box>
  );
};
