import React from 'react';
import {
  Grid,
  Paper,
  Box,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Link
} from '@mui/material';
import { Link as ReactRouterLink } from 'react-router-dom';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { LoginFormType } from '../../../types/types';
import { emailValidationRegex } from '../../../constants/regex';
import { useAuth } from '../../../contexts/AuthContext';
import { useNetwork } from '../../../utils/network';
import { useUser } from '../../../contexts/UserContext';
import { useShowSnackbar } from '../../../utils/snackbar';
type LoginFormProps = {
  setRememberUser: (rememberUser: boolean) => void;
  setPreAccessToken: (preAccessToken: string | null) => void;
};

export const LoginForm = ({
  setRememberUser,
  setPreAccessToken
}: LoginFormProps) => {
  const { setAccessToken } = useAuth();
  const { showSnackbar } = useShowSnackbar()
  const { setUser } = useUser()
  const { post } = useNetwork();
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<LoginFormType>();

  const onSubmit: SubmitHandler<LoginFormType> = async (data) => {
    setRememberUser(data.rememberUser ?? false);

    try {
      const result = await post('/auth/login',
        {
          email: data.email,
          password: data.password,
          rememberUser: data.rememberUser
        }, false)

      if (!!result.accessToken) {
        setAccessToken(result.accessToken);
        setUser(result.user)
      } else if (!!result.preAccessToken) {
        setPreAccessToken(result.preAccessToken);
      }
    }
    catch (error: any) {
      showSnackbar(error.response.data.message, 'error');
    }
  };

  return (
    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
      <Box
        sx={{
          my: 8,
          mx: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Typography component="h1" variant="h4">
          Login
        </Typography>
        <Box sx={{ mt: 1 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="email"
              control={control}
              rules={{
                required: true,
                pattern: emailValidationRegex
              }}
              render={({ field }) => (
                <TextField
                  id="email"
                  margin="normal"
                  required
                  fullWidth
                  label="Email"
                  autoFocus
                  autoComplete="email"
                  error={Boolean(errors.email)}
                  helperText={errors.email && 'Please enter a valid email'}
                  {...field}
                />
              )}
            />

            <Controller
              name="password"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  label="Password"
                  type="password"
                  id="password"
                  autoFocus
                  {...field}
                />
              )}
            />
            <Controller
              name="rememberUser"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox color="primary" />}
                  label="Remember me"
                  {...field}
                />
              )}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link
                  component={ReactRouterLink}
                  to="/set-password"
                  variant="body2"
                >
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link component={ReactRouterLink} to="/sign-up" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </Grid>
  );
};
