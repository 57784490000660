import React, { useEffect, useState } from 'react';
import { differenceInSeconds, intervalToDuration } from 'date-fns';
import { Box, Typography } from '@mui/material';

type CountdownTimerProps = {
  nextGiveaway: Date;
  isTiny?: boolean;
  fieldsToShow?: Array<'days' | 'hours' | 'minutes' | 'seconds'>;
};

export const CountdownTimer = ({
  nextGiveaway,
  isTiny = false,
  fieldsToShow = ['days', 'hours', 'minutes', 'seconds'], // Default to all fields
}: CountdownTimerProps) => {
  const [countdownTimer, setCountdownTimer] = useState<number>(
    differenceInSeconds(nextGiveaway, new Date())
  );

  const zeroPad = (num: number) => String(num).padStart(2, '0');
  const duration = intervalToDuration({ start: 0, end: countdownTimer * 1000 });

  useEffect(() => {
    if (countdownTimer > 0) {
      const timeout = setTimeout(() => {
        setCountdownTimer(countdownTimer - 1);
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [countdownTimer]);

  const renderField = (label: string, value: number | undefined) => (
    <Box
      sx={{
        bgcolor: '#727D77',
        borderRadius: '4px',
        padding: '1rem',
      }}
    >
      <Typography textAlign="center">
        {label.toUpperCase()}
      </Typography>
      <Typography textAlign="center">
        {zeroPad(value ?? 0)}
      </Typography>
    </Box>
  );

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '1rem',
      }}
    >
      {fieldsToShow.includes('days') && renderField('Days', duration.days)}
      {fieldsToShow.includes('hours') && renderField('Hours', duration.hours)}
      {fieldsToShow.includes('minutes') && renderField('Mins', duration.minutes)}
      {fieldsToShow.includes('seconds') && renderField('Secs', duration.seconds)}
    </Box>
  );
};
