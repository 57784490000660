import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider
} from '@mui/material';
import {
  ConnectSocketAdditionalParams,
  DeckType
} from '../../../../types/types';
import { useAuth } from '../../../../contexts/AuthContext';

type MatchEndScreenProps = {
  winner: string;
  username: string;
  resetGame: () => void;
  connectSocket: (additionalParams: ConnectSocketAdditionalParams) => void;
  userDecks: DeckType[];
  selectedDeckName: string;
  setSelectedDeckName: (value: string) => void;
};

export const MatchEndScreen = ({
  winner,
  username,
  resetGame,
  connectSocket,
  userDecks,
  selectedDeckName,
  setSelectedDeckName
}: MatchEndScreenProps) => {
  const { accessToken } = useAuth();
  const [lobbyUsers, setLobbyUsers] = useState<number | 'error' | null>(null);
  const [usersInGame, setUsersInGame] = useState<number | 'error' | null>(null);

  useEffect(() => {
    fetchLobbyUsers();
    fetchUsersInGame();
  }, []);

  const fetchLobbyUsers = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_TCG_URL}/globalLobby`
      );
      const data = await response.json();
      console.log('fetchLobbyUsers data', data);
      setLobbyUsers(data.globalLobbyCount);
    } catch (error) {
      setLobbyUsers('error');
    }
  };

  const fetchUsersInGame = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_TCG_URL}/gameRooms`
      );
      const data = await response.json();
      setUsersInGame(data.totalUsersPlaying);
    } catch (error) {
      setUsersInGame('error');
    }
  };

  const playAgainHandler = (botGame: boolean) => {
    resetGame();
    connectSocket({
      botGame,
      deckName: selectedDeckName,
      token: accessToken ?? ''
    });
  };

  const renderPlayButtons = () => {
    return (
      <Box sx={{ border: '1px solid white', borderRadius: '4px', mx: '1rem' }}>
        <Typography
          variant="h5"
          fontWeight="bold"
          textAlign="center"
          sx={{ p: '1rem' }}
        >
          Play Again?
        </Typography>

        <Divider />

        <Box
          sx={{
            pt: '1rem',
            display: 'flex',
            justifyContent: 'center',
            px: '1rem'
          }}
        >
          <FormControl sx={{ width: '250px' }}>
            <InputLabel id="deck-select-label">Select Deck</InputLabel>
            <Select
              labelId="deck-select-label"
              id="deck-select"
              label="Select Deck"
              onChange={(e) => {
                setSelectedDeckName(e.target.value);
              }}
              value={selectedDeckName}
            >
              {userDecks.map((deck, index) => {
                return (
                  <MenuItem key={index} value={deck.deckName}>
                    {deck.deckName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>

        <Box
          sx={{
            py: '2rem',
            px: '1rem',
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Button
            size="large"
            variant="contained"
            onClick={() => playAgainHandler(false)}
            sx={{ width: '100px' }}
          >
            Users
          </Button>
          <Button
            size="large"
            variant="contained"
            onClick={() => playAgainHandler(true)}
            sx={{ width: '100px' }}
          >
            Bot
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: `calc(100vh - 4rem)`
      }}
    >
      {/* <Box
        sx={{ display: 'flex', mt: '-6rem', justifyContent: 'space-between' }}
      >
        {lobbyUsers !== null && lobbyUsers !== 'error' && (
          <Box
            sx={{
              width: '125px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid white',
              borderRadius: '4px',
              p: '1rem',
              mr: '2rem'
            }}
          >
            <Typography variant="h4" fontWeight="bold">
              {lobbyUsers}
            </Typography>
            <Typography variant="h6" fontWeight="bold">
              Waiting
            </Typography>
          </Box>
        )}
        {usersInGame !== null && usersInGame !== 'error' && (
          <Box
            sx={{
              width: '125px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid white',
              borderRadius: '4px',
              p: '1rem'
            }}
          >
            <Typography variant="h4" fontWeight="bold">
              {usersInGame}
            </Typography>
            <Typography variant="h6" fontWeight="bold">
              Playing
            </Typography>
          </Box>
        )}
      </Box> */}
      {winner !== 'draw' &&
        winner !== username &&
        winner !== 'Opponent disconnected' && (
          <Box>
            <Typography
              variant="h2"
              textAlign="center"
              fontWeight="bold"
              sx={{
                // pt: '2rem', enable this if keeping numbers of players waiting and stuff
                px: '1rem'
              }}
            >
              MATCH LOST
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '2rem'
              }}
            >
              {renderPlayButtons()}
            </Box>
          </Box>
        )}
      {winner !== 'draw' &&
        (winner === username || winner === 'Opponent disconnected') && (
          <Box>
            <Typography
              variant="h1"
              textAlign="center"
              fontWeight="bold"
              sx={{
                // pt: '2rem', enable this if keeping numbers of players waiting and stuff
                px: '1rem'
              }}
            >
              MATCH WON
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '2rem'
              }}
            >
              {renderPlayButtons()}
            </Box>
          </Box>
        )}
      {winner === 'draw' && (
        <Box>
          <Typography
            variant="h1"
            textAlign="center"
            fontWeight="bold"
            sx={{
              // pt: '2rem', enable this if keeping numbers of players waiting and stuff
              px: '1rem'
            }}
          >
            DRAW
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '2rem'
            }}
          >
            {renderPlayButtons()}
          </Box>
        </Box>
      )}
    </Box>
  );
};
