export enum CardRarityEnum {
  Common = 'Common',
  Rare = 'Rare',
  Epic = 'Epic',
  Legendary = 'Legendary',
  Relic = 'Relic'
}

export enum CardTypeEnum {
  Token = 'Token',
  TokenWarrior = 'Warrior',
  Influencer = 'Influencer'
}

export enum PackageTypeEnum {
  giveaway,
  subscription
}
