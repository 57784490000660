import React, { useState } from 'react';
import {
  Grid,
  Paper,
  Box,
  Typography,
  TextField,
  Button,
  Link
} from '@mui/material';
import { Link as ReactRouterLink, useSearchParams } from 'react-router-dom';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import {
  PasswordResetEmailType,
  PasswordResetPasswordType
} from '../../types/types';
import { emailValidationRegex } from '../../constants/regex';
import { useNetwork } from '../../utils/network';
import { useShowSnackbar } from '../../utils/snackbar';
import './SetPassword.css'
import { JoinNow } from '../../components/WhitePaper'

type Props = {};

export const SetPassword = (props: Props) => {
  const { post } = useNetwork()
  const { showSnackbar } = useShowSnackbar()
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const hasResetReferralLink: string = searchParams.get('code') || '';

  const [isPasswordResetSuccessful, setIsPasswordResetSuccessful] =
    useState<boolean>(false);

  const {
    handleSubmit: handleEmailSubmit,
    control: emailControl,
    formState: { errors: emailErrors }
  } = useForm<PasswordResetEmailType>();

  const {
    handleSubmit: handlePasswordSubmit,
    control: passwordControl,
    formState: { errors: passwordErrors }
  } = useForm<PasswordResetPasswordType>();

  const onEmailSubmit: SubmitHandler<PasswordResetEmailType> = async (data) => {
    try {
      await post('/auth/forgot-password', { email: data.email })

      setIsPasswordResetSuccessful(true);
      setIsEmailSent(true);
    } catch (error: any) {
      showSnackbar(error.response.data.message, 'error');
    }
  };

  const onPasswordSubmit: SubmitHandler<PasswordResetPasswordType> = async (
    data
  ) => {
    try {
      await post('/auth/set-password', {
        password: data.password,
        activationCode: hasResetReferralLink
      })

      setIsPasswordResetSuccessful(true);
    } catch (error: any) {
      showSnackbar(error.response.data.message, 'error');
    }
  };

  return (
    <Grid container component="main" sx={{ height: '100vh' }} className='set-password'>
      <JoinNow />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          {!hasResetReferralLink && !isEmailSent && (
            <>
              <Typography component="h1" variant="h4">
                Password Reset
              </Typography>
              <Box
                sx={{
                  mt: '5rem',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  width: '100%'
                }}
              >
                <form onSubmit={handleEmailSubmit(onEmailSubmit)}>
                  <Controller
                    name="email"
                    control={emailControl}
                    rules={{
                      required: true,
                      pattern: emailValidationRegex
                    }}
                    render={({ field }) => (
                      <TextField
                        id="email"
                        margin="normal"
                        required
                        fullWidth
                        label="Email"
                        autoFocus
                        autoComplete="email"
                        error={Boolean(emailErrors.email)}
                        helperText={
                          emailErrors.email && 'Please enter a valid email'
                        }
                        {...field}
                      />
                    )}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Send email
                  </Button>
                  <Grid container>
                    <Grid item>
                      <Link
                        component={ReactRouterLink}
                        to="/login"
                        variant="body2"
                      >
                        {'Back to Login'}
                      </Link>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </>
          )}
          {!hasResetReferralLink && isEmailSent && (
            <>
              <Typography component="h1" variant="h4">
                Password Reset
              </Typography>
              <Box sx={{ mt: '5rem' }}>
                <Typography variant="body1">
                  If user exists an email has been sent. Please check your email for password
                  reset link
                </Typography>
              </Box>
              <Grid sx={{ width: '100%', mt: '2rem' }}>
                <Grid item sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Link component={ReactRouterLink} to="/login" variant="body2">
                    <Button variant="contained">Back to Login</Button>
                  </Link>
                </Grid>
              </Grid>
            </>
          )}
          {hasResetReferralLink && !isPasswordResetSuccessful && (
            <>
              <Typography component="h1" variant="h4">
                Set Password
              </Typography>
              <Box
                sx={{
                  mt: '5rem',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  width: '100%'
                }}
              >
                <form onSubmit={handlePasswordSubmit(onPasswordSubmit)}>
                  <Controller
                    name="password"
                    control={passwordControl}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Password"
                        type="password"
                        id="password"
                        autoFocus
                        error={Boolean(passwordErrors.confirmPassword)}
                        helperText={
                          passwordErrors.confirmPassword &&
                          'Passwords do not match'
                        }
                        {...field}
                      />
                    )}
                  />

                  <Controller
                    name="confirmPassword"
                    control={passwordControl}
                    rules={{
                      required: true
                    }}
                    render={({ field }) => (
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Confirm Password"
                        type="password"
                        id="confirmPassword"
                        autoFocus
                        error={Boolean(passwordErrors.confirmPassword)}
                        helperText={
                          passwordErrors.confirmPassword &&
                          'Passwords do not match'
                        }
                        {...field}
                      />
                    )}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Submit
                  </Button>
                </form>
              </Box>
            </>
          )}

          {hasResetReferralLink && isPasswordResetSuccessful && (
            <>
              <Typography component="h1" variant="h4">
                Password Reset
              </Typography>
              <Box sx={{ mt: '5rem' }}>
                <Typography variant="body1">
                  Password has been reset. Please login with your new password
                </Typography>
                <Grid sx={{ width: '100%', mt: '2rem' }}>
                  <Grid item sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Link
                      component={ReactRouterLink}
                      to="/login"
                      variant="body2"
                    >
                      <Button variant="contained">Back to Login</Button>
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};
