import React, { useRef, useState } from 'react';
import {
  Box,
  IconButton,
  Avatar,
  Typography,
  TextField,
  Button,
  Badge,
  InputAdornment
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { PhotoCameraIcon, CopyIcon } from '../../../../constants/icons';
import { useUser } from '../../../../contexts/UserContext';
import { useNetwork } from '../../../../utils/network';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
export const SettingsUserProfileSettings = () => {
  const { user, setUser } = useUser()
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { put } = useNetwork();

  const StyledBadge = styled(Badge)(() => ({
    '& .MuiBadge-badge': {
      width: '2rem',
      height: '2rem',
      borderRadius: '50%'
    }
  }));

  const referralLink = `${window.location.origin}/sign-up?referralCode=${user.referralCode}`;

  const copyReferralLink = async () => {
    await navigator.clipboard.writeText(referralLink);
  };

  const handleIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("image", file);

      try {
        await put("/user/profile-picture", formData, true)
        setUser({...user, refreshImage: new Date().toISOString()})
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };
  return (
    <Box
      sx={{
        margin: '0 1rem 0 1rem',
      }}
    >
      <Box>
        <Box
          sx={{
            backgroundColor: 'divider',
            borderRadius: '4px',
            padding: '2rem',
            height: '50%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              badgeContent={
                <IconButton
                  sx={{
                    bgcolor: 'grey',
                    ':hover': { bgcolor: 'darkgrey' }
                  }}
                  size="large"
                  onClick={handleIconClick}
                >
                  <PhotoCameraIcon fontSize="small"/>
                  <input
                    type="file"
                    accept="image/*"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </IconButton>
              }
            >
              <Avatar
                alt={user.username}
                src={`${process.env.REACT_APP_PROFILE_PICTURE_URL}/${user.uuid}.jpg?refresh=${user.refreshImage}`}
                sx={{
                  width: '9.5rem',
                  height: '9.5rem',
                  border: '3px solid black',
                  boxShadow: '0 0 0 1px grey',
                }}>
                {user.username.charAt(0).toUpperCase()}
              </Avatar>
            </StyledBadge>
          </Box>
          <Box sx={{ mt: '2rem' }}>
            <Typography textAlign="center" variant="h5" fontWeight="bold">
              {user.username}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: 'divider',
            borderRadius: '4px',
            mt: '1rem',
            height: '47.5%'
          }}
        >
          <Box sx={{ p: '1rem', py: '2rem' }}>
            <Typography textAlign="center" variant="h5" fontWeight="bold">
              Referral Code:
            </Typography>
            <Typography textAlign="center" variant="h3" fontWeight="bold">
              {user.referralCode}
            </Typography>

            <Box
              sx={{
                mt: '1rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <TextField
                margin="normal"
                fullWidth
                id="referral link"
                value={referralLink}
                onFocus={(event) => {
                  event.target.select();
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={copyReferralLink}>
                        <ContentCopyIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />;
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
