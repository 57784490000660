import React from 'react';
import {
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Box
} from '@mui/material';
import {
  PurchasePackagesType,
  SubscriptionPackagesType
} from '../../types/types';
import { PackageTypeEnum } from '../../types/enums';

type CardComponentProps = {
  purchasePackage: PurchasePackagesType | SubscriptionPackagesType;
  handlePurchaseSelection?: (
    purchasePackage: PurchasePackagesType | SubscriptionPackagesType,
    packageType: PackageTypeEnum
  ) => void;
  width?: string;
  height?: string;
  // imageWidth?: string;
  imageHeight?: string;
  showImage?: boolean;
  showFooter?: boolean;
  cardBorder?: boolean;
  showPopularBanner?: boolean;
  showValueBanner?: boolean;
  isSubscription?: boolean;
};

export const CardComponent = ({
  purchasePackage,
  handlePurchaseSelection = () => {},
  width,
  height,
  // imageWidth,
  imageHeight = '300px',
  showImage = true,
  showFooter = true,
  cardBorder = false,
  showPopularBanner = false,
  showValueBanner = false,
  isSubscription = false
}: CardComponentProps) => {
  return (
    <Card
      sx={{
        width: width,
        height: height,
        border: cardBorder ? '1px solid yellow' : '',
        position: 'relative'
      }}
    >
      {showPopularBanner && (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 1,
            bgcolor: 'red',
            width: '100%'
          }}
        >
          <Typography variant="h4" textAlign="center">
            Most Popular
          </Typography>
        </Box>
      )}
      {showValueBanner && (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 1,
            bgcolor: 'red',
            width: '100%'
          }}
        >
          <Typography variant="h4" textAlign="center">
            Most Value
          </Typography>
        </Box>
      )}
      {showImage && (
        <CardMedia sx={{ height: imageHeight }} image={`${process.env.REACT_APP_API_URL}/${purchasePackage.imageUrl}`} />
      )}
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {purchasePackage.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {purchasePackage.description}
        </Typography>
      </CardContent>
      {showFooter ? (
        <CardActions>
          <Box
            sx={{
              mx: '0.5rem',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%'
            }}
          >
            <Typography
              variant="body1"
              color="text.secondary"
              fontWeight="bold"
            >
              {isSubscription
                ? `$${purchasePackage.price} per month`
                : `$${purchasePackage.price}`}
            </Typography>
            <Button
              size="small"
              onClick={() =>
                handlePurchaseSelection(
                  purchasePackage,
                  isSubscription
                    ? PackageTypeEnum.subscription
                    : PackageTypeEnum.giveaway
                )
              }
              variant="contained"
            >
              Buy
            </Button>
          </Box>
        </CardActions>
      ) : (
        <CardActions>
          <Box sx={{ height: '1.9rem' }}>
            <Box
              sx={{
                mx: '0.5rem',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%'
              }}
            >
              <Typography
                variant="body1"
                color="text.secondary"
                fontWeight="bold"
              >
                Current plan
              </Typography>
            </Box>
          </Box>
        </CardActions>
      )}
    </Card>
  );
};
