import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

interface AuthContextType {
  isLoggedIn: boolean;
  accessToken: string | null;
  setAccessToken: (token: string | null) => void;
  redirectUrl: string | null;
  saveRedirectUrl: () => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  const [accessToken, setAccessToken] = useState<string | null>(() => {
    return localStorage.getItem('incrypt_app_accessToken');
  });
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null)

  useEffect(() => {
    if (accessToken) {
      localStorage.setItem('incrypt_app_accessToken', accessToken);
    } else {
      localStorage.removeItem('incrypt_app_accessToken');
    }
  }, [accessToken]);

  const isLoggedIn = !!accessToken;

  const logout = () => {
    setAccessToken(null);
    localStorage.removeItem('incrypt_app_userData');
    localStorage.removeItem('incrypt_app_redirectUrl');
  };

  const saveRedirectUrl = () => {
    setRedirectUrl(location.pathname)
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, accessToken, setAccessToken, logout, redirectUrl, saveRedirectUrl }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
