import { useState } from 'react';
import { PurchasePackagesType, SelectedPurchaseType, SubscriptionPackagesType } from '../../../types/types';
import { PackageTypeEnum } from '../../../types/enums';

export const usePurchaseModal = () => {
  const [isBuyModalOpen, setIsBuyModalOpen] = useState<boolean>(false);
  const [selectedPurchase, setSelectedPurchase] = useState<SelectedPurchaseType | null>(null);

  const handlePurchaseModalClose = () => {
    setSelectedPurchase(null);
    setIsBuyModalOpen(false);
  };

  const handlePurchaseSelection = (
    packageData: PurchasePackagesType | SubscriptionPackagesType,
    packageType: PackageTypeEnum
  ) => {
    setSelectedPurchase({ ...packageData, type: packageType });
    setIsBuyModalOpen(true);
  };

  return {
    isBuyModalOpen,
    selectedPurchase,
    handlePurchaseModalClose,
    handlePurchaseSelection,
  };
};
