import React from 'react';
import { Grid } from '@mui/material';
import ReactPlayer from 'react-player';

export const JoinNow = () => {
  return (
    <Grid
      item
      xs={false}
      sm={4}
      md={7}
      container
      justifyContent="center"
      alignItems="center"
      sx={{
        height: '100vh',
        display: { xs: 'none', sm: 'flex' },
        bgcolor: 'black',
        width: '100%',
        position: 'sticky',
        overflow: 'hidden'
      }}
    >
      <ReactPlayer
        url="https://incrypt-public-assets.s3.ap-southeast-2.amazonaws.com/login_video.mp4"
        width="100%"
        height="100%"
        muted={true}
        playing={true}
        loop={true}
        style={{
          objectFit: 'cover',
          maxHeight: '100%',
          maxWidth: '100%'
        }}
      />
    </Grid>
  );
};
