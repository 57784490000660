import React, { useState } from 'react';
import { Box, Typography, Avatar, Button, CircularProgress } from '@mui/material';
import DiscordLogo from '../../../assets/Images/discord-logo.png';
import { useNetwork } from '../../../utils/network';
import { useUser } from '../../../contexts/UserContext';
import { useShowSnackbar } from '../../../utils/snackbar';
import { useNavigate } from 'react-router-dom';

export const DiscordPromo = () => {
  const { post } = useNetwork();
  const { setUser, user } = useUser();
  const { showSnackbar } = useShowSnackbar()
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const handleClaim = async () => {
    setLoading(true);
    try {
      const result = await post('/user/1000-discord-members', {}, true);
      
      setUser({
        ...user,
        tokenAmount: result.tokenAmount,
      });

      showSnackbar(result.message, 'success')
      navigate('/member-portal')
    } catch (error: any) {
      showSnackbar(error.response.data.message, 'error')
    } finally {
      setLoading(false);

    }
  };

  return (
    <Box 
      display="flex" 
      flexDirection="column" 
      alignItems="center" 
      justifyContent="center" 
      sx={{ padding: 4, textAlign: 'center', maxWidth: 400, margin: '0 auto' }}
    >
      <Avatar 
        alt="Discord Logo" 
        src={DiscordLogo} 
        sx={{ width: 300, height: 150, marginBottom: 2 }}
      />
      <Typography variant="h5" component="h1" gutterBottom>
        Congratulations on 1,000 Members!
      </Typography>
      <Typography variant="body1">
        As part of our celebration for reaching 1,000 members on Discord, you can claim 100 tokens! Thank you for being a part of our community.
      </Typography>

      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleClaim} 
        sx={{ marginTop: 3 }}
        disabled={loading}
      >
        {loading ? <CircularProgress size={24} /> : 'Claim Now'}
      </Button>
    </Box>
  );
}
