import React, { useEffect } from 'react';
import { Box, Typography, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { add, differenceInSeconds } from 'date-fns';

type MatchHUDProps = {
  username: string;
  userHp: number;
  opponentUsername: string;
  opponentHp: number;
  roundStartTimer: Date | null;
  countdownTimer: number;
  setCountdownTimer: (value: number) => void;
  hasOpponentDisconnected: boolean;
  playerBattleDamageTaken: number | null;
  playerEffectDamageTaken: number | null;
  playerHealAmount: number | null;
  opponentBattleDamageTaken: number | null;
  opponentEffectDamageTaken: number | null;
  opponentHealAmount: number | null;
};

export const MatchHUD = ({
  username,
  userHp,
  opponentUsername,
  opponentHp,
  roundStartTimer,
  countdownTimer,
  setCountdownTimer,
  hasOpponentDisconnected,
  playerBattleDamageTaken,
  playerEffectDamageTaken,
  playerHealAmount,
  opponentBattleDamageTaken,
  opponentEffectDamageTaken,
  opponentHealAmount
}: MatchHUDProps) => {
  const StyledPlayerHUD = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.divider,
    borderRadius: '4px',
    width: '35%',
    [theme.breakpoints.down('md')]: {
      width: '45%'
    }
  }));

  const StyledOpponentHUD = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.divider,
    borderRadius: '4px',
    width: '35%',
    [theme.breakpoints.down('md')]: {
      width: '45%'
    }
  }));

  const defaultMaxHP = 500;

  useEffect(() => {
    if (roundStartTimer) {
      setCountdownTimer(
        differenceInSeconds(add(roundStartTimer, { seconds: 30 }), new Date())
      );
    }
  }, [roundStartTimer]);

  useEffect(() => {
    const countDown = setTimeout(() => {
      setCountdownTimer(countdownTimer - 1);
    }, 1000);

    return () => {
      clearTimeout(countDown);
    };
  }, [countdownTimer]);

  const currentHealthPercentage = (health: number, maxHealth: number) => {
    return `${Math.floor((health * 100) / maxHealth)}%`;
  };

  return (
    <Box sx={{ height: '15vh' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: '0.3rem'
        }}
      >
        <StyledPlayerHUD>
          <Box>
            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{
                textShadow:
                  '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
              }}
            >
              {username}
            </Typography>
            <Box
              sx={{
                width: '100%',
                position: 'relative',
                height: '50px'
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  zIndex: 1,
                  height: '100%',
                  bgcolor: 'white',
                  borderRadius: '16px',
                  width: '100%'
                }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  zIndex: 2,
                  bgcolor: 'red',
                  borderRadius: '16px',
                  height: '100%',
                  width: currentHealthPercentage(userHp, defaultMaxHP)
                }}
              />
              <Typography
                sx={{
                  position: 'absolute',
                  zIndex: 5,
                  left: '42.5%',
                  top: '20%',
                  textShadow:
                    '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                }}
                variant="h6"
                fontWeight="bold"
              >
                {userHp}/{defaultMaxHP}
              </Typography>
            </Box>
            <Box
              sx={{
                visibility:
                  playerBattleDamageTaken !== null ||
                  playerEffectDamageTaken !== null ||
                  playerHealAmount !== null
                    ? ''
                    : 'hidden',
                height: '2rem',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Typography
                variant="body1"
                fontWeight="bold"
                sx={{
                  textShadow:
                    '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                }}
              >
                Healing:
              </Typography>
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{
                  textShadow:
                    '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
                  color: 'green'
                }}
              >
                {playerHealAmount}
              </Typography>
              <Divider orientation="vertical" flexItem />
              <Typography
                variant="body1"
                fontWeight="bold"
                sx={{
                  textShadow:
                    '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                }}
              >
                EFT Damage:
              </Typography>
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{
                  textShadow:
                    '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
                  color: 'red'
                }}
              >
                {playerEffectDamageTaken}
              </Typography>
              <Divider orientation="vertical" flexItem />
              <Typography
                variant="body1"
                fontWeight="bold"
                sx={{
                  textShadow:
                    '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                }}
              >
                BTL Damage:
              </Typography>
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{
                  textShadow:
                    '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
                  color: 'red'
                }}
              >
                {playerBattleDamageTaken}
              </Typography>
            </Box>
          </Box>
        </StyledPlayerHUD>

        <Typography
          textAlign="center"
          variant="h2"
          fontWeight="bold"
          sx={{
            textShadow: '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
          }}
        >
          {countdownTimer}
        </Typography>

        <StyledOpponentHUD>
          <Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: hasOpponentDisconnected
                  ? 'space-between'
                  : 'end'
              }}
            >
              {hasOpponentDisconnected && (
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  sx={{ color: 'red' }}
                >
                  (Disconnected)
                </Typography>
              )}
              <Typography
                variant="h5"
                fontWeight="bold"
                sx={{
                  textShadow:
                    '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                }}
              >
                {opponentUsername}
              </Typography>
            </Box>
            <Box
              sx={{
                width: '100%',
                position: 'relative',
                height: '50px'
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  zIndex: 1,
                  height: '100%',
                  bgcolor: 'white',
                  borderRadius: '16px',
                  width: '100%'
                }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  zIndex: 2,
                  bgcolor: 'red',
                  borderRadius: '16px',
                  height: '100%',
                  width: currentHealthPercentage(opponentHp, defaultMaxHP)
                }}
              />
              <Typography
                sx={{
                  position: 'absolute',
                  zIndex: 5,
                  left: '42.5%',
                  top: '20%',
                  textShadow:
                    '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                }}
                variant="h6"
                fontWeight="bold"
              >
                {opponentHp}/{defaultMaxHP}
              </Typography>
            </Box>
            <Box
              sx={{
                visibility:
                  opponentBattleDamageTaken !== null ||
                  opponentEffectDamageTaken !== null ||
                  opponentHealAmount !== null
                    ? ''
                    : 'hidden',
                height: '2rem',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Typography
                variant="body1"
                fontWeight="bold"
                sx={{
                  textShadow:
                    '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                }}
              >
                Healing:
              </Typography>
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{
                  textShadow:
                    '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
                  color: 'green'
                }}
              >
                {opponentHealAmount}
              </Typography>
              <Typography
                variant="body1"
                fontWeight="bold"
                sx={{
                  textShadow:
                    '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                }}
              >
                EFT Damage:
              </Typography>
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{
                  textShadow:
                    '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
                  color: 'red'
                }}
              >
                {opponentEffectDamageTaken}
              </Typography>
              <Typography
                variant="body1"
                fontWeight="bold"
                sx={{
                  textShadow:
                    '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                }}
              >
                BTL Damage:
              </Typography>
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{
                  textShadow:
                    '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
                  color: 'red'
                }}
              >
                {opponentBattleDamageTaken}
              </Typography>
            </Box>
          </Box>
        </StyledOpponentHUD>
      </Box>
    </Box>
  );
};
