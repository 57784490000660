import React, { useState, useEffect } from 'react';
import { SelectedPurchaseType } from '../../../types/types';
import {
  Modal,
  Box,
  Typography,
  Button,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  TextField,
} from '@mui/material';
import { useUser } from '../../../contexts/UserContext';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useNetwork } from '../../../utils/network';
import { PackageTypeEnum } from '../../../types/enums';
import { useShowSnackbar } from '../../../utils/snackbar';
const stripePromise = loadStripe('pk_test_51OZqtIGQSoUR5POGUUxF8C4Ju8wRrO79GvNpVgPIVy8xl50L5UzV67XPec1rFcGBnDk9oWNdjmbw2ZmPxmeUVdsk00UAFtmDlV');

type PurchaseModalProps = {
  isModalOpen: boolean;
  handlePurchaseModalClose: () => void;
  selectedPurchase: SelectedPurchaseType | null;
};

const PurchaseModalContent = ({
  isModalOpen,
  handlePurchaseModalClose,
  selectedPurchase,
}: PurchaseModalProps) => {
  const { user } = useUser();
  const { get, post } = useNetwork();
  const { showSnackbar } = useShowSnackbar();
  const stripe = useStripe();
  const elements = useElements();
  const [coinPrice, setCoinPrice] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [stripeLoading, setStripeLoading] = useState<boolean>(false);
  const [acceptTnC, setAcceptTnC] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [savedPaymentMethods, setSavedPaymentMethods] = useState<any[]>([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string>('new');
  const [tokenAmountInput, setTokenAmountInput] = useState<string>('0.00');
  const [tokenAmount, setTokenAmount] = useState<number>(0);
  const [fiatAmount, setFiatAmount] = useState<number>(selectedPurchase?.price ?? 0);

  const purchaseModalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    if (isModalOpen && selectedPurchase) {
      setFiatAmount(Number(selectedPurchase.price));
    }
  }, [isModalOpen, selectedPurchase]);

  const resetState = () => {
    setCoinPrice(null);
    setLoading(false);
    setStripeLoading(false);
    setAcceptTnC(false);
    setMessage('');
    setSavedPaymentMethods([]);
    setSelectedPaymentMethod('new');
    setTokenAmountInput('0.00');
    setTokenAmount(0);
    setFiatAmount(0);
    handlePurchaseModalClose()
  };

  useEffect(() => {
    if (isModalOpen) {
      const fetchCoinPrice = async () => {
        setLoading(true);
        try {
          // const response = await axios.get(
          //   'https://api.coingecko.com/api/v3/simple/price?ids=solana&vs_currencies=aud'
          // );
          setCoinPrice(2);
        } catch (error) {
          console.error('Error fetching coin price:', error);
        } finally {
          setLoading(false);
        }
      };

      const fetchSavedPaymentMethods = async () => {
        try {
          const data = await get('/user/payment-methods', true);
          setSavedPaymentMethods(data.paymentMethods);
        } catch (error) {
          console.error('Error fetching saved payment methods:', error);
        }
      };

      fetchCoinPrice();
      fetchSavedPaymentMethods();
    }
  }, [isModalOpen]);

  const handleTokenAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMessage('');
    const value = parseFloat(e.target.value);
    const maxUserTokens = user.tokenAmount;

    if (value < 0) {
      setMessage('Token amount cannot be negative.');
      return;
    }
    setTokenAmountInput(e.target.value);


    if (coinPrice !== null) {
      const totalPackagePrice = selectedPurchase?.price || 0;
      const maxTokenValue = Math.min(totalPackagePrice / coinPrice, maxUserTokens);
      if (value > maxTokenValue) {
        // Automatically use the max token value if the entered value exceeds it
        setTokenAmountInput(maxTokenValue.toFixed(2));
        setTokenAmount(maxTokenValue);
        setFiatAmount(Math.max(totalPackagePrice - (maxTokenValue * coinPrice), 0));
        setMessage('Token amount reached.');
      } else {
        const fiatOffset = value * coinPrice;
        const remainingFiatValue = Math.max(totalPackagePrice - fiatOffset, 0);

        setTokenAmount(value);
        setFiatAmount(remainingFiatValue);
      }
    }
  };

  const handleFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!stripe || !elements) {
      setMessage('Stripe has not loaded. Please refresh the page and try again.');
      return;
    }
  
    setStripeLoading(true);
    setMessage('');
  
    try {
      // First, submit the elements if a new payment method is being used
      if (selectedPaymentMethod === 'new') {
        const { error: submitError } = await elements.submit();
        if (submitError) {
          setMessage(submitError.message || 'Failed to submit payment details.');
          setStripeLoading(false);
          return;
        }
      }
  
      const resData = await post(`/checkout/user/create-standalone-payment-intent`,
        {
          packageId: selectedPurchase?.id || '',
          tokenAmount,
          fiatAmount,
          email: user.email,
          name: user.username,
          phone: '',
          state: '',
          paymentMethodId: selectedPaymentMethod !== 'new' ? selectedPaymentMethod : null,
        }, true);
  
      if (!resData.fiatPaymentRequired) {
        setMessage('Payment successful using tokens!');
        return;
      }
  
      if (selectedPaymentMethod === 'new') {
        const { error } = await stripe.confirmPayment({
          elements,
          clientSecret: resData.clientSecret,
          confirmParams: {
            return_url: `${window.location}`,
          },
        });
  
        if (error) {
          showSnackbar(error.message || 'An unexpected error occurred.', 'error');
        } else {
          showSnackbar('Payment successful!', 'success');
        }
      } else {
        const { error } = await stripe.confirmCardPayment(resData.clientSecret, {
          payment_method: selectedPaymentMethod,
          return_url: `${window.location}`,
        });

        if (error) {
          showSnackbar(error.message || 'An unexpected error occurred.', 'error');
        } else {
          showSnackbar('Payment successful using saved method!', 'success');
        }
      }
    } catch (error) {
      showSnackbar(
        error instanceof Error ? error.message : 'An unexpected error occurred.',
        'error'
      );
    } finally {
      setStripeLoading(false);
    }
  };
  

  return (
    <Modal
      open={isModalOpen && !!selectedPurchase}
      onClose={resetState}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          ...purchaseModalStyle,
          display: 'flex',
          alignItems: 'center',
          flexFlow: 'column',
          width: '60%',
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          !!selectedPurchase && coinPrice !== null && (
            <form onSubmit={handleFormSubmit}>
              <Box
                component="img"
                src={`${process.env.REACT_APP_API_URL}/${selectedPurchase.imageUrl}`}
                sx={{ width: '120px' }}
              />
              <Typography
                id="purchase-package-modal-title"
                variant="h2"
                component="h2"
              >
                {selectedPurchase.name}
              </Typography>
              <Typography id="purchase-package-modal-description" sx={{ mt: 2 }}>
                {selectedPurchase.description}
              </Typography>
              <Box sx={{ mt: '2rem', width: '100%' }}>
                {user.tokenAmount > 0 && <>
                  <TextField
                    label="Token Amount"
                    type="number"
                    inputProps={{ step: '0.01' }}
                    value={tokenAmountInput}
                    onChange={handleTokenAmountChange}
                    fullWidth
                    margin="normal"
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      if (coinPrice !== null && selectedPurchase) {
                        const maxTokenValue = Math.min(user.tokenAmount, selectedPurchase.price / coinPrice);
                        setTokenAmountInput(maxTokenValue.toFixed(2));
                        setTokenAmount(maxTokenValue);
                        setFiatAmount(Math.max(selectedPurchase.price - (maxTokenValue * coinPrice), 0));
                      }
                    }}
                    fullWidth
                    sx={{ mt: 2 }}
                  >Pay with token</Button>
                </>}
                <Typography>Fiat Amount: ${fiatAmount.toFixed(2)}</Typography>
                {fiatAmount > 0 && <div>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Payment Method</FormLabel>
                    <RadioGroup
                      value={selectedPaymentMethod}
                      onChange={(e) => setSelectedPaymentMethod(e.target.value)}
                    >
                      {savedPaymentMethods.map((method) => (
                        <FormControlLabel
                          key={method.id}
                          value={method.id}
                          control={<Radio />}
                          label={`Saved card ending in ${method.card.last4}`}
                        />
                      ))}
                      <FormControlLabel
                        value="new"
                        control={<Radio />}
                        label="New Card"
                      />
                    </RadioGroup>
                  </FormControl>
                  {selectedPaymentMethod === 'new' && <PaymentElement />}
                </div>}
              </Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={acceptTnC}
                    onChange={(e) => setAcceptTnC(e.target.checked)}
                    required
                  />
                }
                label="I accept the terms and conditions."
              />
              {message && (
                <Typography
                  variant="body2"
                  color="error"
                  sx={{
                    marginTop: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontWeight: 'bold',
                  }}
                >
                  {message}
                </Typography>
              )}
              <Box mt={2}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  style={{ marginTop: '20px' }}
                  disabled={!stripe || stripeLoading}
                >
                  {stripeLoading ? <CircularProgress size={24} /> : 'Submit'}
                </Button>
              </Box>
            </form>
          )
        )}
      </Box>
    </Modal>
  );
};

export const PurchaseModal = (props: PurchaseModalProps) => (
  <Elements stripe={stripePromise}
    options={{
      mode: props.selectedPurchase?.type === PackageTypeEnum.giveaway ? 'payment' : 'subscription',
      amount: 200,
      currency: 'aud',
      appearance: {
        theme: 'stripe',
        variables: {
          colorPrimary: 'black',
          colorBackground: 'white',
          colorText: 'black',
        },
        rules: {
          '.Label': {
            color: 'white',
          },
        },
      },
    }}>
    <PurchaseModalContent {...props} />
  </Elements>
);