import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  TextField,
  Box,
} from '@mui/material';
import QRCode from 'qrcode';
import { useUser } from '../../../contexts/UserContext';
import { useNetwork } from '../../../utils/network';
import { useShowSnackbar } from '../../../utils/snackbar';

type TwoFactorAuthModalProps = {
  isTwoFAModalOpen: boolean;
  handleTwoFAModalClose: () => void;
};

export const TwoFactorAuthModal = ({
  isTwoFAModalOpen,
  handleTwoFAModalClose,
}: TwoFactorAuthModalProps) => {
  const { user, setUser } = useUser();
  const { get, post } = useNetwork();
  const { showSnackbar } = useShowSnackbar();
  const [qrcodeUrl, setQrCodeUrl] = useState('');
  const [base32Code, setBase32Code] = useState('');
  const [verificationCode, setVerificationCode] = useState<string>('');

  useEffect(() => {
    generateTwoFactorAuth();
  }, []);

  const generateTwoFactorAuth = async () => {
    try {
      const result = await get('/user/otp/generate', true);
      QRCode.toDataURL(result.twoFactorAuthUrl).then(setQrCodeUrl);
      setBase32Code(result.base32);
    } catch (error: any) {
      showSnackbar(error.response.data.message, 'error');
    }
  };

  const verifyTwoFactorAuthSubmit = async () => {
    try {
      const result = await post(
        '/user/otp/verify',
        { token: verificationCode },
        true
      );
      setUser({
        ...user,
        hasTwoFactorAuthEnabled: true,
      });
      handleTwoFAModalClose();
      showSnackbar(result.message, 'success');
    } catch (error: any) {
      showSnackbar(error.response.data.message, 'error');
    }
  };

  return (
    <Dialog
      open={isTwoFAModalOpen}
      onClose={handleTwoFAModalClose}
      fullWidth
      maxWidth="sm"
      scroll="paper" // Enable scrolling for content
    >
      <DialogTitle textAlign="center" sx={{ fontWeight: 'bold' }}>
        Two-Factor Authentication (2FA)
      </DialogTitle>

      <DialogContent dividers>
        <Box sx={{ px: 2 }}>
          <Typography
            variant="h6"
            fontWeight="bold"
            textAlign="center"
            sx={{ pb: '0.5rem' }}
          >
            Configuring Google Authenticator or Authy
          </Typography>
          <Typography>
            1. Install Google Authenticator (IOS - Android) or Authy (IOS -
            Android).
          </Typography>
          <Typography>2. In the authenticator app, select "+" icon.</Typography>
          <Typography>
            3. Select "Scan a barcode (or QR code)" and use the phone's camera
            to scan this barcode.
          </Typography>

          <Box
            sx={{ display: 'flex', justifyContent: 'center', py: '1rem' }}
          >
            <Box
              component="img"
              src={qrcodeUrl}
              alt="qrcode url"
              sx={{
                width: { xs: '128px', sm: '192px', md: '192px' },
                height: { xs: '128px', sm: '192px', md: '192px' },
              }}
            />
          </Box>

          <Box sx={{ pb: '2rem' }}>
            <Typography
              variant="h6"
              fontWeight="bold"
              textAlign="center"
              sx={{ pb: '0.5rem' }}
            >
              Or Enter Code Into Your App (Google Authenticator or Authy)
            </Typography>
            <Typography textAlign="center" sx={{ wordBreak: 'break-word' }}>
              {`SecretKey: ${base32Code}`} <br /> (Base32 encoded)
            </Typography>
          </Box>

          <Box
            sx={{
              borderTop: '1px solid grey',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              pt: '1rem',
            }}
          >
            <Typography textAlign="center" sx={{ pb: '1rem' }}>
              Enter Code from Authenticator app below to verify
            </Typography>
            <TextField
              id="authAppCode"
              onChange={(e) => setVerificationCode(e.currentTarget.value)}
            />
          </Box>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          onClick={verifyTwoFactorAuthSubmit}
        >
          Verify and Activate
        </Button>
        <Button variant="contained" onClick={handleTwoFAModalClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
